import React from 'react';
import { useTranslation } from 'react-i18next';
import FooterPersona from '../FooterPersona/FooterPersona';
import Head from '../Head/Head';
import LoadingPage from '../LoadingPage/LoadingPage';
import Collaboratif from './Collaboratif/Collaboratif';
import Engagement from './Engagement/Engagement';
import Expertises from './Expertises/Expertises';
import Histoire from './Histoire/Histoire';
import International from './International/International';
import References from './References/References';

function Vision() {
	const { t, ready } = useTranslation('vision');

	return ready ? (
		<>
			<Head
				title={t('title')}
				description={t('description')}
				img="/img/opengraph/engagement.png"
			/>

			<Histoire />
			<Expertises />
			<International />
			<Collaboratif />
			<References />
			<Engagement />
			<FooterPersona />
		</>
	) : (
		<LoadingPage />
	);
}

export default Vision;
