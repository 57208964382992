import React from 'react';
import { useInView } from 'react-intersection-observer';
import slugify from 'slugify';
import styles from './Clients.module.scss';
import { allClients, pngClients } from './variables';

interface ClientsProps {
	className: string;
	clients: typeof allClients[number][];
}

function Clients({ clients, className }: ClientsProps) {
	const [ref, inView] = useInView({
		rootMargin: '-200px 0px 0px',
		triggerOnce: true,
	});

	return (
		<ul
			ref={ref}
			className={[styles.wrapper, className, inView && styles.inView]
				.filter(Boolean)
				.join(' ')}
		>
			{clients.map((client, i) => {
				const isPng = pngClients.find((c) => c === client)!;
				const src = `/img/clients/${slugify(client, { lower: true })}`;

				return (
					<li
						key={i}
						className={styles.item}
						style={{ transitionDelay: i * 50 + 'ms' }}
					>
						<figure>
							<img
								alt={client}
								className={styles.img}
								src={src + (isPng ? '.png' : '.svg')}
							/>
						</figure>
					</li>
				);
			})}
			<li className={[styles.item, styles.fake].join(' ')} />
			<li className={[styles.item, styles.fake].join(' ')} />
			<li className={[styles.item, styles.fake].join(' ')} />
		</ul>
	);
}

export default Clients;
