import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { Parallax, useController } from 'react-scroll-parallax';
import { media } from '../../../../utils/breakpoints/breakpoints';
import { pageTransitionDuration } from '../../../AnimatedRoute/AnimatedRoute';
import styles from './ScrollText.module.scss';

interface ScrollTextProps {
	className: string;
}

function ScrollText({ className }: ScrollTextProps) {
	const { t } = useTranslation('secteurs');

	const isMobile = useMediaQuery({ query: media['<laptop'] });

	const { parallaxController } = useController();

	useEffect(() => {
		setTimeout(() => parallaxController.update(), pageTransitionDuration + 150);
	}, [parallaxController]);

	return (
		<div className={[styles.wrapper, className].join(' ')}>
			<Parallax
				tagInner="p"
				x={[80, -100]}
				disabled={isMobile}
				className={styles.parallax}
			>
				{t('scrollSlider')}
			</Parallax>
		</div>
	);
}

export default ScrollText;
