import React, {
	createContext,
	useContext,
	useEffect,
	useRef,
	useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import routes from '../../routes';
import { ProfilTypes } from '../../routes/persona';
import { SecteurTypes } from '../../routes/secteurs';
import { Lang } from '../../types';
import Arrow from '../Arrow/Arrow';
import CallToAction from '../CallToAction/CallToAction';
import Popin from '../Popin/Popin';
import styles from './ModulePersona.module.scss';
import Slider from './Slider/Slider';

export const PersonaContext = createContext<{
	isOpen: boolean;
	open: () => void;
	close: () => void;
}>({ isOpen: false, close: () => null, open: () => null });

function ModulePersona() {
	const { t, i18n, ready } = useTranslation('module-persona');

	const { isOpen, close } = useContext(PersonaContext);

	const history = useHistory();
	const { key } = useLocation();
	const oldKey = useRef(key);

	const [step, setStep] = useState<1 | 2>(1);
	const [profil, setProfil] = useState<ProfilTypes>();
	const [secteur, setSecteur] = useState<SecteurTypes>();

	const route =
		profil && secteur && routes.persona[profil][secteur][i18n.language as Lang];

	useEffect(() => {
		if (key !== oldKey.current) close();
		oldKey.current = key;
	}, [key, close]);

	useEffect(() => {
		if (popinRef.current) popinRef.current.scrollTop = 0;
	}, [step]);

	const popinRef = useRef<HTMLDivElement>(null);

	function goToNextStep() {
		if (step === 1) {
			setStep(2);
		} else {
			history.push(route!);
			setSecteur(undefined);
			setProfil(undefined);
			setStep(1);
		}
	}

	return ready ? (
		<Popin
			ref={popinRef}
			isOpen={isOpen}
			onCacheClick={close}
			className={styles.wrapper}
		>
			<button
				onClick={() => setStep(1)}
				className={[styles.arrow, step === 1 && styles.hidden]
					.filter(Boolean)
					.join(' ')}
			>
				<Arrow direction="left" />
			</button>

			<p className={styles.index}>
				{t('step')} {step} / 2
			</p>

			<Slider
				step={step}
				profil={profil}
				secteur={secteur}
				setProfil={setProfil}
				setSecteur={setSecteur}
				goToNextStep={goToNextStep}
			/>

			<button
				onClick={goToNextStep}
				className={[
					styles.cta,
					((step === 2 && !secteur) || !profil) && styles.hidden,
				]
					.filter(Boolean)
					.join(' ')}
			>
				<CallToAction>{t('confirm')}</CallToAction>
			</button>
		</Popin>
	) : null;
}

export default ModulePersona;
