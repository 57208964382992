import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import slugify from 'slugify';
import Img from '../../Img/Img';
import styles from './Temoignages.module.scss';

interface TemoignagesProps {
	className: string;
}

function Temoignages({ className }: TemoignagesProps) {
	const { t } = useTranslation('carrieres');

	const [index, setIndex] = useState(0);
	const [direction, setDirection] = useState<'left' | 'right'>('right');

	const list = t('temoignages', { returnObjects: true, defaultValue: [] }) as {
		author: string;
		text: string;
	}[];

	return list.length ? (
		<div className={[styles.wrapper, className].join(' ')}>
			<div className={styles.nav}>
				<button
					onClick={() => {
						setDirection('left');
						setIndex((index - 1 + list.length) % list.length);
					}}
				>
					<span className={styles.arrow} />
				</button>

				<button
					onClick={() => {
						setDirection('right');
						setIndex((index + 1 + list.length) % list.length);
					}}
				>
					<span className={styles.arrow} />
				</button>
			</div>

			<TransitionGroup className={styles[direction]}>
				<CSSTransition key={index} classNames={styles} timeout={{ exit: 300 }}>
					<article className={styles.block}>
						<header className={styles.header}>
							<Img
								width={70}
								height={70}
								widths={[70, 140]}
								className={styles.img}
								alt={list[index].author}
								sizes={{ default: '70px' }}
								src={`/img/temoignages/${slugify(list[index].author, {
									lower: true,
								})}`}
							/>

							<div>
								<h2 className={styles.title}>{t('temoignage')}</h2>
								<p className={styles.author}>{list[index].author}</p>
							</div>
						</header>

						<p className={styles.text}>{list[index].text}</p>
					</article>
				</CSSTransition>
			</TransitionGroup>
		</div>
	) : null;
}

export default Temoignages;
