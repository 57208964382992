import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ReactComponent as LogoOrcom } from '../../../utils/logo-orcom.svg';
import BigText from '../../BigText/BigText';
import BigTitle from '../../BigTitle/BigTitle';
import Img from '../../Img/Img';
import Quotation from '../../Quotation/Quotation';
import TextBlock from '../../TextBlock/TextBlock';
import styles from './Histoire.module.scss';

function Histoire() {
	const { t } = useTranslation('vision');

	const bigText =
		(t('histoire.bigText', {
			returnObjects: true,
			defaultValue: null,
		}) as string[]) || [];

	return (
		<article id="histoire" className={styles.wrapper}>
			<BigTitle tag="h1" size="big" className={styles.title} startsPage>
				{t('histoire.title')}
			</BigTitle>

			<TextBlock
				className={styles.block1}
				title={t('histoire.block1.title')}
				content={<Trans i18nKey="vision:histoire.block1.content" />}
			/>

			<Quotation
				className={styles.quotation}
				content={t('histoire.quotation.content')}
				author={t('histoire.quotation.author')}
			/>

			<Img
				parallax
				width={720}
				height={339}
				widths={[360, 720, 1360]}
				alt={t('histoire.associés')}
				className={styles.photoAssocies}
				src="/img/vision/histoire/collaboratif"
				sizes={{ '<laptop': '100vw', '<=desktop': '42.7vw', default: '35.5vw' }}
			/>

			<TextBlock
				className={styles.block2}
				title={t('histoire.block2.title')}
				content={<Trans i18nKey="vision:histoire.block2.content" />}
			/>

			<LogoOrcom className={styles.logoOrcom} />

			<BigText className={styles.bigText}>
				{bigText
					.map((line, i) =>
						line.split(' ').map((word) => ({ word, stroke: i === 0 })),
					)
					.flat()}
			</BigText>
		</article>
	);
}

export default Histoire;
