import { TranslatedRoute } from '.';

export const secteursTrads = [
	['routes-transports', 'roads-transport'],
	['mobilite-smart-city', 'mobility-smart-city'],
	['immobilier', 'real-estate'],
	['infrastructures-sociales', 'social-infrastructures'],
	['energies-utilities', 'energy-utilities'],
	['telecoms', 'telecoms'],
] as const;

export type SecteurTypes = typeof secteursTrads[number][0];

export const listSecteurs = secteursTrads.map(([secteurFR, secteurEN]) => {
	return {
		key: secteurFR,
		routes: { fr: `/fr/secteur/${secteurFR}`, en: `/en/sector/${secteurEN}` },
	} as { key: SecteurTypes; routes: { fr: string; en: string } };
});

export default listSecteurs.reduce((acc, curr) => {
	acc[curr.key] = curr.routes;
	return acc;
}, Object.create(null)) as Record<SecteurTypes, TranslatedRoute>;
