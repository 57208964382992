import formatDate from 'dateformat';
import React from 'react';
import { useTranslation } from 'react-i18next';
import routes from '../../../../routes';
import Link from '../../../Link/Link';
import styles from './Bottom.module.scss';

interface BottomProps {
	className: string;
}

function Bottom({ className }: BottomProps) {
	const { t } = useTranslation('common');

	return (
		<div className={[styles.wrapper, className].join(' ')}>
			<Link to={routes['mentions-légales']} className={styles.link}>
				{t('mentions-legales')}
			</Link>

			<p className={styles.copyright}>
				© {formatDate('yyyy')} H3P &nbsp; | &nbsp;{' '}
				{t('creation-realisation') + '\u202f'}:{' '}
				<a
					target="_blank"
					className={styles.link}
					rel="noopener noreferrer"
					href="https://adelios.fr"
				>
					Adelios
				</a>
			</p>
		</div>
	);
}

export default Bottom;
