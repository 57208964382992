import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import InView from 'react-intersection-observer';
import routes from '../../../routes';
import BigText from '../../BigText/BigText';
import BigTitle from '../../BigTitle/BigTitle';
import CallToAction from '../../CallToAction/CallToAction';
import Illustration from '../../Illustration/Illustration';
import { illustrations } from '../../Illustration/illustrations';
import Link from '../../Link/Link';
import TextBlock from '../../TextBlock/TextBlock';
import styles from './Collaboratif.module.scss';
import Partners from './Partners/Partners';

function Collaboratif() {
	const { t } = useTranslation('vision');

	const [showMore, setShowMore] = useState(false);

	const bigText =
		(t('collaboratif.bigText', {
			returnObjects: true,
			defaultValue: null,
		}) as string[]) || [];

	return (
		<article id="collaboratif" className={styles.wrapper}>
			<BigTitle tag="h1" size="big" className={styles.title}>
				{t('collaboratif.title')}
			</BigTitle>

			<Illustration
				parallax
				className={styles.img}
				{...illustrations.collaboratif}
				alt={t('collaboratif.block1.title')}
			/>

			<TextBlock
				className={styles.block1}
				title={t('collaboratif.block1.title')}
				content={<Trans i18nKey="vision:collaboratif.block1.content" />}
			/>

			<InView triggerOnce rootMargin="-100px 0px 0px">
				{({ ref, inView }) => (
					<div
						ref={ref}
						className={[styles.cta, inView && styles.inView]
							.filter(Boolean)
							.join(' ')}
					>
						<p className={styles.ctaTitle}>
							<Trans i18nKey="vision:collaboratif.cta.title" />
						</p>
						<Link to={routes.carrières}>
							<CallToAction className={styles.ctaButton}>
								{t('collaboratif.cta.button')}
							</CallToAction>
						</Link>
					</div>
				)}
			</InView>

			<BigText className={styles.bigText}>
				{bigText
					.map((line, i) =>
						line.split(' ').map((word) => ({ word, stroke: i === 0 })),
					)
					.flat()}
			</BigText>

			<Partners showMore={showMore} className={styles.partnersList} />

			{!showMore && (
				<button className={styles.showMore} onClick={() => setShowMore(true)}>
					<CallToAction>{t('collaboratif.showMore')}</CallToAction>
				</button>
			)}
		</article>
	);
}

export default Collaboratif;
