import React, { useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { useHistory, useLocation } from 'react-router-dom';
import { useWheelTouchAndKeyboard } from '../../hooks/useWheelTouchAndKeyboard';
import routes from '../../routes';
import { media } from '../../utils/breakpoints/breakpoints';
import BigText from '../BigText/BigText';
import BigTitle from '../BigTitle/BigTitle';
import CallToAction from '../CallToAction/CallToAction';
import Head from '../Head/Head';
import Illustration from '../Illustration/Illustration';
import { illustrations } from '../Illustration/illustrations';
import Link from '../Link/Link';
import LoadingPage from '../LoadingPage/LoadingPage';
import TextBlock from '../TextBlock/TextBlock';
import ExpertiseBlocks from './ExpertiseBlocks/ExpertiseBlocks';
import styles from './Expertises.module.scss';
import Menu from './Menu/Menu';
import PopinSolutions from './PopinSolutions/PopinSolutions';
import Slider from './Slider/Slider';

const transitionDuration = 600;

export interface Expertise {
	title: string;
	content: string;
	solutions: string[];
}

function Expertises() {
	const { t, ready } = useTranslation('expertises');
	const [popinOpen, setPopinOpen] = useState(false);
	const { pathname, search } = useLocation();
	const history = useHistory();
	const isTransiting = useRef(false);

	const isMobile = useMediaQuery({ query: media['<tablet'] });

	const page = useRef<HTMLDivElement>(null);

	const slider = useRef<HTMLDivElement>(null);

	const expertises: Expertise[] = ready
		? t('expertises', { returnObjects: true, defaultValue: [] })
		: [];

	const params = new URLSearchParams(search);
	let active = params.get('e') || '';
	if (+active >= expertises.length) active = '0';

	const [memoActive, setMemoActive] = useState(+active || 0);

	useEffect(() => {
		if (active) setMemoActive(+active);
	}, [active]);

	const bigText = ready
		? (t('bigText.array', {
				returnObjects: true,
				defaultValue: [],
		  }) as string[])
		: [];

	useWheelTouchAndKeyboard({
		area: page,
		preventScroll: false,
		goTo: (delta) => {
			if (isTransiting.current || delta === -1 || popinOpen || isMobile) return;
			isTransiting.current = true;
			history.push(`${pathname}?e=${+active + delta}`);
			setTimeout(() => (isTransiting.current = false), transitionDuration);
		},
	});

	return ready ? (
		<article ref={page} className={styles.wrapper}>
			<Head
				title={t(`title`)}
				description={t(`description`)}
				img="/img/opengraph/expertises.png"
			/>

			<BigTitle tag="h1" size="big" className={styles.title} startsPage>
				{t('title')}
			</BigTitle>

			<Illustration
				alt={t('title')}
				{...illustrations.expertises}
				className={styles.illustration}
			/>

			<TextBlock
				className={styles.block1}
				title={t('block1.title')}
				content={<Trans i18nKey={'expertises:block1.content'} />}
				link={{ children: t('block1.cta'), to: routes.carte }}
			/>

			<TextBlock
				className={styles.block2}
				title={t('block2.title')}
				content={<Trans i18nKey={'expertises:block2.content'} />}
				link={{
					children: t('block2.cta'),
					to: routes.secteurs['routes-transports'],
				}}
			/>

			<Menu
				active={memoActive}
				className={styles.menu}
				scrollToElement={slider.current}
			/>

			<BigText className={styles.bigText}>
				{bigText
					.map((line, i) =>
						line.split(' ').map((word) => ({ word, stroke: i === 1 })),
					)
					.flat()}
			</BigText>

			<Link className={styles.bigTextCTA} to={routes.carrières}>
				<CallToAction>{t('bigText.cta')}</CallToAction>
			</Link>

			<Slider ref={slider} active={memoActive} className={styles.slider} />

			<ExpertiseBlocks
				className={styles.expertises}
				expertise={expertises[memoActive]}
				openPopin={() => setPopinOpen(true)}
			/>

			<PopinSolutions
				isOpen={popinOpen}
				close={() => setPopinOpen(false)}
				expertise={expertises[memoActive]}
			/>
		</article>
	) : (
		<LoadingPage />
	);
}

export default Expertises;
