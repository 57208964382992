import React, { useEffect, useRef, useState } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import Illustration from '../../Illustration/Illustration';
import { ReadyIllustration } from '../../Illustration/illustrations';
import Aside from './Aside/Aside';
import Bottom from './Bottom/Bottom';
import styles from './Menu.module.scss';
import Pages from './Pages/Pages';
import Persona from './Persona/Persona';
import { pages } from './variables';

interface MenuProps {
	isOpen: boolean;
	close: () => void;
}

function Menu({ isOpen, close }: MenuProps) {
	const [img, setImg] = useState<ReadyIllustration>(pages[0].subPages![0].img);

	const wrapper = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (isOpen && wrapper.current) wrapper.current.scrollTop = 0;
	}, [isOpen]);

	return (
		<div
			ref={wrapper}
			className={[styles.wrapper, isOpen && styles.isOpen]
				.filter(Boolean)
				.join(' ')}
		>
			<nav className={styles.nav}>
				<Pages setImg={setImg} className={styles.pages} />

				<div className={styles.imgWrapper}>
					<TransitionGroup className={styles.imgSubWrapper}>
						<CSSTransition
							unmountOnExit
							classNames={styles}
							timeout={{ exit: 200 }}
							key={img.background.src}
						>
							<Illustration alt="" {...img} className={styles.img} />
						</CSSTransition>
					</TransitionGroup>
				</div>

				<Persona className={styles.persona} />
				<div className={styles.greyCache} />

				<Aside className={styles.aside} />

				<Bottom className={styles.bottom} />
			</nav>

			<div className={styles.cache} onClick={close} />
		</div>
	);
}

export default Menu;
