import React, { forwardRef, Ref } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './Slider.module.scss';

interface SliderProps {
	active: number;
	className: string;
}

const Slider = forwardRef(
	({ active, className }: SliderProps, ref: Ref<HTMLDivElement>) => {
		const { t } = useTranslation('expertises');

		const expertises = t('expertises', { returnObjects: true });

		return (
			<section ref={ref} className={[styles.wrapper, className].join(' ')}>
				<div className={styles.index}>{('0' + (active + 1)).slice(-2)}</div>
				<div className={styles.line}>
					<div
						style={{
							width: 100 / expertises.length + '%',
							transform: `translateX(${100 * active}%)`,
						}}
					/>
				</div>
				<div className={styles.total}>
					{('0' + expertises.length).slice(-2)}
				</div>
			</section>
		);
	},
);

export default Slider;
