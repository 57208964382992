import React, { FormEvent, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { TransitionStatus } from 'react-transition-group/Transition';
import styles from './Form.module.scss';

interface FormProps {
	search: string;
	searchActive: boolean;
	state: TransitionStatus;
	onSubmit: (e: FormEvent) => void;
	setSearch: (search: string) => void;
}

function Form({ search, onSubmit, state, setSearch, searchActive }: FormProps) {
	const { t } = useTranslation('common');

	const oldSearch = useRef('');

	useEffect(() => {
		oldSearch.current = search;
	}, [search]);

	return (
		<form
			onSubmit={onSubmit}
			className={[styles.wrapper, styles[state], searchActive && styles.top]
				.filter(Boolean)
				.join(' ')}
		>
			<label className={styles.label}>
				<input
					required
					autoFocus
					type="search"
					minLength={3}
					value={search}
					className={styles.input}
					placeholder={t('recherche.rechercher')}
					onChange={e => setSearch(e.target.value)}
				/>
			</label>

			<button
				type="submit"
				className={[
					styles.submit,
					oldSearch.current === search && styles.hidden,
				]
					.filter(Boolean)
					.join(' ')}
			/>
		</form>
	);
}

export default Form;
