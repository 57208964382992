import React from 'react';
import { Parallax } from 'react-scroll-parallax';
import { getSizes, getSrcSet, ImgSizes } from '../../utils/img';
import styles from './Illustration.module.scss';

interface SingleImg {
	src: string;
	sizes: ImgSizes;
	/** first width is the default width */
	widths: number[];
}

interface IllustrationProps {
	alt: string;
	className: string;
	color: string;
	/** positive = faster than scroll, negative = slower, default -100 */
	parallax?: boolean | number;
	background: SingleImg;
	animations: (SingleImg & {
		className?: string;
		pos: { x: number; y: number; w: number };
	})[];
}

function getProps(props: SingleImg) {
	return {
		loading: 'lazy' as const,
		width: props.sizes.default,
		sizes: getSizes(props.sizes),
		...getSrcSet({ ext: 'png', src: props.src, widths: props.widths }),
	};
}

function Illustration(props: IllustrationProps) {
	/* square */

	return (
		<Parallax
			tagInner="div"
			tagOuter="figure"
			disabled={!props.parallax}
			styleInner={{ backgroundColor: props.color }}
			className={[styles.figure, props.className].filter(Boolean).join(' ')}
			y={
				typeof props.parallax === 'number'
					? [props.parallax + 'px', props.parallax * -1 + 'px']
					: ['-100px', '100px']
			}
		>
			<img
				alt={props.alt}
				className={styles.background}
				{...getProps(props.background)}
				height={props.background.sizes.default}
			/>

			{props.animations.map(({ pos, ...img }, i) => {
				return (
					<img
						alt=""
						key={i}
						{...getProps(img)}
						style={{ width: pos.w + '%', left: pos.x + '%', top: pos.y + '%' }}
						className={[styles.animation, img.className]
							.filter(Boolean)
							.join(' ')}
					/>
				);
			})}
		</Parallax>
	);
}

export default Illustration;
