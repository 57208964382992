import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import routes, { personaRoutes } from '../../routes';
import International from './International/International';
import Nuages from './Nuages/Nuages';
import Persona from './Persona/Persona';
import styles from './Slider.module.scss';
import Vision from './Vision/Vision';

const homeVision = Object.values(routes.home.vision);
const homePersona = Object.values(routes.home.persona);
const homeInternational = Object.values(routes.home.international);

const pagePersona = personaRoutes.map((r) => Object.values(r)).flat();
const pageVision = Object.values(routes.vision);

function Slider() {
	const { pathname } = useLocation();

	const isSlideVision = homeVision.includes(pathname);
	const isSlidePersona = homePersona.includes(pathname);
	const isSlideInternational = homeInternational.includes(pathname);

	const isPagePersona = pagePersona.includes(pathname);
	const isPageVision = pageVision.includes(pathname);

	const isScrolled = isPagePersona || isPageVision;

	const slide: 'vision' | 'persona' | 'international' | null =
		isSlideVision || isPageVision
			? 'vision'
			: isSlidePersona || isPagePersona
			? 'persona'
			: isSlideInternational
			? 'international'
			: null;

	const oldSlideRef = useRef(slide);
	const [oldSlide, setOldSlide] = useState(slide);
	const [memorizedSlide, setMemorizedSlide] = useState(slide);

	useEffect(() => {
		if (slide) {
			setOldSlide(oldSlideRef.current);
			oldSlideRef.current = slide;
			setMemorizedSlide(slide);
		}
	}, [slide]);

	return (
		<div className={styles.wrapper}>
			<CSSTransition
				in={!!slide}
				unmountOnExit
				timeout={1000}
				classNames={styles}
			>
				<div
					className={[
						styles.canvas,
						isScrolled && styles.scrolled,
						`slide slide-${memorizedSlide} old-slide-${oldSlide}`,
					]
						.filter(Boolean)
						.join(' ')}
				>
					<Persona visible={memorizedSlide === 'persona'} />
					<International visible={memorizedSlide === 'international'} />
					<Nuages />
					<Vision visible={memorizedSlide === 'vision'} />
				</div>
			</CSSTransition>
		</div>
	);
}

export default Slider;
