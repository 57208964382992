import React, { useEffect, useRef, useState } from 'react';
import styles from './Filtre.module.scss';

export type Option = { label: string; value: string };

interface FiltreProps {
	title: string;
	current?: Option;
	options: Option[];
	className: string;
	color: 'blue' | 'orange';
	setOption: (option?: Option) => void;
}

function Filtre({
	title,
	color,
	current,
	options,
	setOption,
	className,
}: FiltreProps) {
	const [open, setOpen] = useState(false);

	const ref = useRef<HTMLDivElement>(null);

	useEffect(() => {
		function blur(e: Event) {
			if (ref.current && !ref.current.contains(e.target as Node)) {
				setOpen(false);
			}
		}

		window.addEventListener('click', blur);
		window.addEventListener('touch', blur);

		return () => {
			window.removeEventListener('click', blur);
			window.removeEventListener('touch', blur);
		};
	}, []);

	const max = [title, ...options.map(o => o.label)].reduce((a, b) =>
		a.length > b.length ? a : b,
	);

	return (
		<div
			ref={ref}
			className={[styles.wrapper, className, styles[color], open && styles.open]
				.filter(Boolean)
				.join(' ')}
		>
			<p className={styles.label} onClick={() => setOpen(!open)}>
				<span className={styles.hidden} aria-hidden>
					{max}
				</span>
				<span className={styles.visible}>{current?.label || title}</span>
			</p>

			<ul
				className={styles.list}
				style={{
					height: open
						? (options.length + 1) * parseInt(styles.optionHeight)
						: 0,
				}}
			>
				<li>
					<button
						onClick={() => {
							setOpen(false);
							setOption(undefined);
						}}
						className={[styles.option, !current && styles.active]
							.filter(Boolean)
							.join(' ')}
					>
						{title}
					</button>
				</li>

				{options.map(option => (
					<li key={option.value}>
						<button
							onClick={() => {
								setOpen(false);
								setOption(option);
							}}
							className={[
								styles.option,
								option.value === current?.value && styles.active,
							]
								.filter(Boolean)
								.join(' ')}
						>
							{option.label}
						</button>
					</li>
				))}
			</ul>
		</div>
	);
}

export default Filtre;
