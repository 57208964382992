import { decodeEntities } from '@wordpress/html-entities';
import { frenchify } from 'frenchify';
import React, { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Lang } from '../../types';
import { getWordPressPostBySlug } from '../../utils/wordpress';
import { WordPressActualite } from '../../utils/wordpress/types';
import { WithAnimatedRouteProps } from '../AnimatedRoute/AnimatedRoute';
import Contact from '../Contact/Contact';
import PageNotFound from '../PageNotFound/PageNotFound';
import Share from '../Share/Share';
import WrapperActuEtude from '../WrapperActuEtude/WrapperActuEtude';
import styles from './Actualite.module.scss';

function Actualite({ params }: WithAnimatedRouteProps<{ slug: string }>) {
	const { i18n } = useTranslation();

	const [post, setPost] = useState<WordPressActualite>();
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(false);

	useEffect(() => {
		let isMounted = true;

		getWordPressPostBySlug<WordPressActualite>({
			type: 'actualite',
			slug: params.slug,
			lang: i18n.language as Lang,
		})
			.then((res) => {
				if (!isMounted) return;
				if (res.length) setPost(res[0]);
				else setError(true);
			})
			.catch(() => isMounted && setError(true))
			.finally(() => isMounted && setLoading(false));

		return () => {
			isMounted = false;
		};
	}, [params.slug, i18n.language]);

	return !error ? (
		<WrapperActuEtude
			type="actualite"
			loading={loading}
			slug={params.slug}
			yoast={post?.head_tags}
			image={post?.acf.image}
			title={decodeEntities(post?.title.rendered || '')}
			description={decodeEntities(
				post?.content.rendered?.replace(/<\/?[^>]+(>|$)/g, '') || '',
			)}
		>
			<>
				<div
					className={styles.content}
					dangerouslySetInnerHTML={{
						__html: decodeEntities(post?.content.rendered || ''),
					}}
				/>

				<div className={styles.contactShare}>
					{post?.acf.contact && <Contact type="short" nom={post.acf.contact} />}

					<Share
						className={styles.share}
						title={frenchify(
							decodeEntities(post?.title.rendered || params.slug),
						)}
					/>
				</div>
			</>
		</WrapperActuEtude>
	) : (
		<PageNotFound />
	);
}

export default memo(Actualite, (prev, next) => {
	return prev.params.slug === next.params.slug;
});
