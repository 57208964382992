import React from 'react';
import { useTranslation } from 'react-i18next';
import BigTitle from '../BigTitle/BigTitle';
import Head from '../Head/Head';
import LoadingPage from '../LoadingPage/LoadingPage';
import styles from './MentionsLegales.module.scss';

type List = {
	title: string;
	content: (string | string[] | { title: string; content: string[] })[];
}[];

function MentionsLegales() {
	const { t, ready } = useTranslation('mentions-legales');

	const list = ready
		? (t('list', { returnObjects: true, defaultValue: [] }) as List)
		: [];

	return ready ? (
		<section className={styles.wrapper}>
			<Head title={t('title')} description={t('description')} />

			<BigTitle tag="h1" size="medium" className={styles.h1} startsPage>
				{t('title')}
			</BigTitle>

			<dl className={styles.preambule}>
				<dt>{t('preambule.forme-juridique')} :</dt>
				<dd>SAS</dd>

				<dt>{t('preambule.siege-social')} :</dt>
				<dd>
					H3P
					<br />
					Tour Franklin
					<br />
					Terrasse Boieldieu 100-101
					<br />
					92800 Puteaux
					<br />
					Tel : 01 85 78 09 00
				</dd>

				<dt>{t('preambule.registre-commerce-societes')} :</dt>
				<dd>
					R.C.S. de Nanterre 508 805 686
					<br />
					APE : 6920Z
					<br />
					N° TVA Intercommunautaire : FR44 508 805 686
				</dd>

				<dt>{t('preambule.directeur-publication')} :</dt>
				<dd>Benjamin Lepesant &amp; Jeremy Vlach</dd>

				<dt>{t('preambule.hebergement')} :</dt>
				<dd>ONLINE SAS – BP 438 75366 PARIS CEDEX 08</dd>

				<dt>{t('preambule.realisation')} :</dt>
				<dd>
					<a
						target="_blank"
						href="https://adelios.fr"
						rel="noopener noreferrer"
					>
						Agence Adelios
					</a>
				</dd>

				<dt>{t('preambule.direction-artistique')} :</dt>
				<dd>
					<a
						target="_blank"
						rel="noopener noreferrer"
						href="https://www.behance.net/pierrepatrault"
					>
						Pierre Patrault
					</a>
				</dd>

				<dt>{t('preambule.illustrations')} :</dt>
				<dd>
					<a
						target="_blank"
						rel="noopener noreferrer"
						href="https://gcw.dunked.com"
					>
						Gabrielle Cooper-Weisz
					</a>
				</dd>

				<dt>{t('preambule.developpement')} :</dt>
				<dd>
					<a
						target="_blank"
						rel="noopener noreferrer"
						href="https://mcquint.com"
					>
						Quentin Durand
					</a>
				</dd>
			</dl>

			<div className={styles.content}>
				{list.map(({ title, content }, i) => (
					<section key={i}>
						<h2>{title}</h2>

						{content.map((obj, o) =>
							typeof obj === 'string' ? (
								<p key={o}>{obj}</p>
							) : Array.isArray(obj) ? (
								<ul key={o}>
									{obj.map((line, u) => (
										<li key={u}>{line}</li>
									))}
								</ul>
							) : (
								<section key={o}>
									<h3>{obj.title}</h3>
									{obj.content.map((text, u) => (
										<p key={u}>{text}</p>
									))}
								</section>
							),
						)}
					</section>
				))}
			</div>
		</section>
	) : (
		<LoadingPage />
	);
}

export default MentionsLegales;
