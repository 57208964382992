import React, { ComponentProps } from 'react';
import { Breakpoint, media } from '../../utils/breakpoints/breakpoints';
import { AcfImage } from '../../utils/wordpress/types';
import Img from '../Img/Img';

interface WordPressImgProps {
	alt: AcfImage['alt'];
	wpSizes: AcfImage['sizes'];
	sizes: ComponentProps<typeof Img>['sizes'];
}

function WordPressImg({ alt, wpSizes, sizes }: WordPressImgProps) {
	const { default: defaultSize, ...facultativeSizes } = sizes;

	const otherSizes = Object.entries(facultativeSizes) as [Breakpoint, string][];

	const formatedSizes = [
		...otherSizes.map(([breakpoint, width]) => media[breakpoint] + ' ' + width),
		defaultSize,
	].join(', ');

	const sources: { src: string; w: number }[] | undefined = wpSizes && [
		{ src: wpSizes.thumbnail, w: wpSizes['thumbnail-width'] },
		{ src: wpSizes.medium, w: wpSizes['medium-width'] },
		{ src: wpSizes.medium_large, w: wpSizes['medium_large-width'] },
		{ src: wpSizes.large, w: wpSizes['large-width'] },
	];

	return (
		<img
			alt={alt}
			src={wpSizes?.thumbnail}
			width={wpSizes?.['medium-width']}
			height={wpSizes?.['medium-height']}
			srcSet={sources?.map(({ src, w }) => `${src} ${w}w`).join(', ')}
			sizes={formatedSizes}
			{...{ loading: 'lazy' }}
		/>
	);
}

export default WordPressImg;
