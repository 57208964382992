import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import routes from '../../../routes';
import BigTitle from '../../BigTitle/BigTitle';
import Illustration from '../../Illustration/Illustration';
import { illustrations } from '../../Illustration/illustrations';
import Quotation from '../../Quotation/Quotation';
import TextBlock from '../../TextBlock/TextBlock';
import styles from './Expertises.module.scss';

function Expertises() {
	const { t } = useTranslation('vision');

	return (
		<article id="expertises" className={styles.wrapper}>
			<BigTitle tag="h1" size="big" className={styles.title}>
				{t('expertises.title')}
			</BigTitle>

			<Illustration
				parallax
				className={styles.img}
				{...illustrations.expertises}
				alt={t('expertises.block1.title')}
			/>

			<TextBlock
				className={styles.block1}
				title={t('expertises.block1.title')}
				content={<Trans i18nKey="vision:expertises.block1.content" />}
			/>

			<Quotation
				className={styles.quotation}
				content={t('expertises.quotation.content')}
				author={t('expertises.quotation.author')}
			/>

			<TextBlock
				className={styles.block2}
				title={t('expertises.block2.title')}
				content={<Trans i18nKey="vision:expertises.block2.content" />}
				link={{ to: routes.expertises, children: t('expertises.block2.cta') }}
			/>
		</article>
	);
}

export default Expertises;
