import React from 'react';
import styles from './ScrollToTop.module.scss';

function ScrollToTop() {
	return (
		<div className={styles.wrapper}>
			<button onClick={() => window.scrollTo(0, 0)} className={styles.button}>
				TOP
			</button>
		</div>
	);
}

export default ScrollToTop;
