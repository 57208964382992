import React from 'react';
import { useTranslation } from 'react-i18next';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { ProfilTypes } from '../../../routes/persona';
import { SecteurTypes } from '../../../routes/secteurs';
import styles from './Slider.module.scss';

interface Item {
	title: string;
	text: string;
	key: ProfilTypes | SecteurTypes;
}

interface SliderProps {
	step: 1 | 2;
	profil?: ProfilTypes;
	secteur?: SecteurTypes;
	setProfil: (key: ProfilTypes) => void;
	setSecteur: (key: SecteurTypes) => void;
	goToNextStep: () => void;
}

function Slider({
	step,
	profil,
	secteur,
	setProfil,
	setSecteur,
	goToNextStep,
}: SliderProps) {
	const { t } = useTranslation('module-persona');

	return (
		<TransitionGroup className={styles.wrapper}>
			<CSSTransition
				key={step}
				mountOnEnter
				unmountOnExit
				classNames={styles}
				timeout={{ exit: 400 }}
			>
				<div className={[styles.slide, styles[step]].join(' ')}>
					<p className={styles.title}>{t(`step${step}.title`)}</p>

					<ul className={styles.list}>
						{(t(`step${step}.list`, {
							returnObjects: true,
							defaultValue: [],
						}) as Item[]).map(({ text, title, key }) => (
							<li
								key={key}
								className={[
									styles.item,
									[profil, secteur].includes(key) && styles.selected,
								]
									.filter(Boolean)
									.join(' ')}
							>
								<button onClick={goToNextStep} className={styles.check} />

								<button
									onClick={() =>
										step === 1
											? setProfil(key as ProfilTypes)
											: setSecteur(key as SecteurTypes)
									}
								>
									<strong>{title}</strong>
									<p>{text}</p>
								</button>
							</li>
						))}
					</ul>
				</div>
			</CSSTransition>
		</TransitionGroup>
	);
}

export default Slider;
