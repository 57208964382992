import React from 'react';
import { ReactComponent as LoupeSVG } from './loupe.svg';
import styles from './SearchButton.module.scss';

interface SearchButtonProps {
	className: string;
	onClick: () => void;
}

function SearchButton({ onClick, className }: SearchButtonProps) {
	return (
		<button className={[styles.wrapper, className].join(' ')} onClick={onClick}>
			<LoupeSVG className={styles.svg} />
		</button>
	);
}

export default SearchButton;
