import React from 'react';
import Arbre from '../Arbre/Arbre';
import Fond from '../Fond/Fond';
import SlideImg from '../SlideImg/SlideImg';
import Square from '../Square/Square';
import styles from './Persona.module.scss';

interface PersonaProps {
	visible: boolean;
}

function Persona({ visible }: PersonaProps) {
	return (
		<>
			<Fond dir="persona" visible={visible} />

			<Square
				className={[styles.square, !visible && 'slide-hidden']
					.filter(Boolean)
					.join(' ')}
			>
				<SlideImg
					x={0}
					y={0}
					width={90}
					className={styles.carnet}
					src="/img/home/persona/carnet/carnet"
					widths={[850, 330, 550, 660, 1100, 1280]}
				/>

				<SlideImg
					x={36}
					y={-12}
					width={2}
					widths={[44]}
					className={styles.fumee}
					src="/img/home/persona/fumee/fumee"
				/>

				<SlideImg
					x={11.5}
					y={35}
					width={47}
					className={styles.fleuve}
					widths={[450, 900, 660, 175, 350]}
					src="/img/home/persona/fleuve/fleuve"
				/>

				<Arbre type={1} x={25} y={5.5} />
				<Arbre type={1} x={49} y={23.5} />

				<SlideImg
					x={9}
					y={14.4}
					width={47.4}
					className={styles.pont}
					src="/img/home/persona/pont/pont"
					widths={[450, 1052, 670, 175, 350]}
				/>

				<Arbre type={1} x={-18} y={7.5} />
				<Arbre type={1} x={-6} y={15.5} />
				<Arbre type={1} x={2} y={56.5} />
				<Arbre type={1} x={21} y={54.5} />
				<Arbre type={1} x={-11} y={26.5} />

				<Arbre type={2} x={64} y={16.5} />
				<Arbre type={2} x={69} y={21.5} />
				<Arbre type={2} x={57} y={19.5} />
				<Arbre type={2} x={25} y={-8.9} />
				<Arbre type={2} x={28} y={-3.9} />
				<Arbre type={2} x={-23} y={18.5} />
				<Arbre type={2} x={-17} y={25.5} />
				<Arbre type={2} x={-21} y={28.5} />
				<Arbre type={2} x={29} y={38.5} />
				<Arbre type={2} x={20} y={40.5} />
				<Arbre type={2} x={25} y={44.5} />
				<Arbre type={2} x={14} y={51.5} />
				<Arbre type={2} x={9.5} y={53.5} />

				<Arbre type={1} x={35} y={44.5} />

				<Arbre type={3} x={-26} y={34.5} />

				<SlideImg
					x={12}
					y={-0.5}
					width={12}
					widths={[270]}
					className={styles.femme}
					src="/img/home/persona/femme/femme"
				/>
			</Square>
		</>
	);
}

export default Persona;
