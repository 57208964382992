import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import CallToAction from '../../../CallToAction/CallToAction';
import { PersonaContext } from '../../../ModulePersona/ModulePersona';
import styles from './Persona.module.scss';

interface PersonaProps {
	className: string;
}

function Persona({ className }: PersonaProps) {
	const { t } = useTranslation();

	const { open } = useContext(PersonaContext);

	return (
		<p className={[styles.wrapper, className].join(' ')}>
			<span className={styles.title}>{t('ctaPersona.title')}</span>

			<button onClick={open}>
				<CallToAction>{t('ctaPersona.button')}</CallToAction>
			</button>
		</p>
	);
}

export default Persona;
