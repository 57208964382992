import React, { useEffect } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { JsonLd } from 'react-schemaorg';
import { Corporation } from 'schema-dts';
import { allRoutes } from '../../routes';
import { Lang } from '../../types';
import {
	city,
	email,
	isProd,
	phone,
	postalCode,
	siteName,
	siteUrl,
	street,
} from '../../utils/env';
import { Yoast } from '../../utils/wordpress/types';

interface HeadProps {
	img?: string;
	title: string;
	noindex?: boolean;
	description: string;
	yoast?: Yoast;
}

function Head({ description, title, img, noindex, yoast }: HeadProps) {
	const { pathname } = useLocation();
	const { t, i18n, ready } = useTranslation('common');

	const headline = ready ? t('headline') : '';
	const fullTitle = `${title} - ${siteName} ${headline}`;
	const url = siteUrl + pathname;
	const langs = { fr: 'fr_FR', en: 'en_US' };

	const route = allRoutes.find((tr) => [tr.en, tr.fr].includes(pathname));

	useEffect(() => {
		document.documentElement.lang = i18n.language;
	}, [i18n.language]);

	const ogType = yoast?.find((t) => t.attributes.property === 'og:type')
		?.attributes.content;

	const ogTitle = yoast?.find((t) => t.attributes.property === 'og:title')
		?.attributes.content;

	const ogDescription = yoast?.find(
		(t) => t.attributes.property === 'og:description',
	)?.attributes.content;

	const ogImage = yoast?.find((t) => t.attributes.property === 'og:image')
		?.attributes.content;

	const articleModifiedTime = yoast?.find(
		(t) => t.attributes.property === 'article:modified_time',
	)?.attributes.content;

	const twitterTitle = yoast?.find((t) => t.attributes.name === 'twitter:title')
		?.attributes.content;

	const twitterDescription = yoast?.find(
		(t) => t.attributes.name === 'twitter:description',
	)?.attributes.content;

	const twitterImage = yoast?.find((t) => t.attributes.name === 'twitter:image')
		?.attributes.content;

	return createPortal(
		<>
			{(!isProd || noindex) && (
				<meta name="robots" content="noindex, nofollow" />
			)}

			{/* Primary Meta Tags */}
			<title>{fullTitle}</title>
			<meta name="title" content={fullTitle} />
			<meta name="description" content={description} />

			{/* PWA */}
			<meta name="application-name" content={siteName + ' - ' + headline} />
			<meta
				name="apple-mobile-web-app-title"
				content={siteName + ' - ' + headline}
			/>

			{/* Open Graph / Facebook */}
			<meta property="og:type" content={ogType || 'website'} />
			<meta property="og:url" content={url} />
			<meta property="og:title" content={ogTitle || fullTitle} />
			<meta property="og:description" content={ogDescription || description} />
			{(!!img || !!ogImage) && (
				<meta property="og:image" content={ogImage || siteUrl + img} />
			)}
			<meta property="og:site_name" content={siteName} />
			<meta property="og:locale" content={langs[i18n.language as Lang]} />
			{!!route && (
				<meta
					property="og:locale:alternate"
					content={i18n.language === 'fr' ? langs.en : langs.fr}
				/>
			)}
			<meta
				property="article:publisher"
				content="https://fr-fr.facebook.com/h3pGroup/"
			/>
			{articleModifiedTime && (
				<meta property="article:modified_time" content={articleModifiedTime} />
			)}

			{/* Twitter */}
			<meta property="twitter:card" content="summary" />
			<meta property="twitter:url" content={url} />
			<meta property="twitter:title" content={twitterTitle || fullTitle} />
			<meta
				property="twitter:description"
				content={twitterDescription || description}
			/>
			{(!!img || !!twitterImage) && (
				<meta
					property="twitter:image"
					content={twitterImage || siteUrl + img}
				/>
			)}
			<meta property="twitter:creator" content="@h3pgroup" />

			{/* Multi language */}
			{!!route && (
				<>
					<link hrefLang="fr" rel="alternate" href={siteUrl + route.fr} />
					<link hrefLang="en" rel="alternate" href={siteUrl + route.en} />
				</>
			)}

			{/* JSON-LD */}
			<JsonLd<Corporation>
				item={{
					'@context': 'https://schema.org',
					'@type': 'Corporation',
					'@id': siteUrl + '/#corporation',
					name: siteName,
					slogan: headline,
					description: (
						(ready &&
							(t('splashScreen', { returnObjects: true }) as string[])) ||
						[]
					).join(' '),
					address: {
						'@type': 'PostalAddress',
						streetAddress: street,
						addressLocality: city,
						postalCode: postalCode,
						addressCountry: 'FR',
					},
					email: email,
					telephone: phone,
					logo: siteUrl + '/icons/logo-h3p.svg',
					image: siteUrl + '/icons/logo-h3p.svg',
					url: siteUrl,
				}}
			/>
		</>,
		document.head,
	);
}

export default Head;
