import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as ReactLink, useLocation } from 'react-router-dom';
import { TranslatedRoute } from '../../routes';
import { Lang } from '../../types';

interface LinkProps {
	hash?: string;
	title?: string;
	search?: string;
	className?: string;
	children: ReactNode;
	to: TranslatedRoute;
	activeClassName?: string;
}

function Link({
	to,
	hash,
	title,
	search,
	children,
	className,
	activeClassName,
}: LinkProps) {
	const { i18n } = useTranslation();
	const { pathname, hash: currentHash } = useLocation();
	const newPathname = to[(i18n.language as Lang) || 'fr'];

	const isActive =
		[to.en, to.fr].includes(pathname) && (hash ? hash === currentHash : true);

	return (
		<ReactLink
			title={title}
			hrefLang={i18n.language}
			replace={pathname === newPathname}
			to={{ pathname: newPathname, search, hash }}
			className={[className, isActive && activeClassName]
				.filter(Boolean)
				.join(' ')}
		>
			{children}
		</ReactLink>
	);
}

export default Link;
