import React from 'react';
import styles from './XButton.module.scss';

interface XButtonProps {
	className: string;
	onClick: () => void;
}

function XButton({ className, onClick }: XButtonProps) {
	return (
		<button
			onClick={onClick}
			className={[styles.button, className].join(' ')}
		/>
	);
}

export default XButton;
