import React from 'react';
import styles from './Burger.module.scss';

interface BurgerProps {
	className: string;
	isOpen: boolean;
	onClick: () => void;
}

function Burger({ className, isOpen, onClick }: BurgerProps) {
	return (
		<button
			onClick={onClick}
			className={[styles.wrapper, className, isOpen && styles.isOpen]
				.filter(Boolean)
				.join(' ')}
		>
			<div className={styles.subWrapper}>
				<div className={styles.line} />
				<div className={styles.line} />
				<div className={styles.line} />
			</div>
		</button>
	);
}

export default Burger;
