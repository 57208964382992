import { decodeEntities } from '@wordpress/html-entities';
import { frenchify } from 'frenchify';
import React, { Fragment, memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import routes from '../../routes';
import { Lang } from '../../types';
import { getWordPressPostBySlug } from '../../utils/wordpress';
import { WordPressEtude } from '../../utils/wordpress/types';
import { WithAnimatedRouteProps } from '../AnimatedRoute/AnimatedRoute';
import BigTitle from '../BigTitle/BigTitle';
import Contact from '../Contact/Contact';
import PageNotFound from '../PageNotFound/PageNotFound';
import TextBlock from '../TextBlock/TextBlock';
import WrapperActuEtude from '../WrapperActuEtude/WrapperActuEtude';
import styles from './EtudeDeCas.module.scss';

function EtudeDeCas({ params }: WithAnimatedRouteProps<{ slug: string }>) {
	const { t, i18n, ready } = useTranslation('actualites');

	const [post, setPost] = useState<WordPressEtude>();
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(false);

	useEffect(() => {
		if (!ready) return;
		let isMounted = true;

		getWordPressPostBySlug<WordPressEtude>({
			type: 'etude',
			slug: params.slug,
			lang: i18n.language as Lang,
		})
			.then((res) => {
				if (!isMounted) return;
				if (res.length) setPost(res[0]);
				else setError(true);
			})
			.catch(() => isMounted && setError(true))
			.finally(() => isMounted && setLoading(false));

		return () => {
			isMounted = false;
		};
	}, [ready, params.slug, i18n.language]);

	return ready && !error ? (
		<WrapperActuEtude
			type="etude"
			loading={loading}
			slug={params.slug}
			yoast={post?.head_tags}
			image={post?.acf.image}
			title={decodeEntities(post?.title.rendered || '')}
			description={decodeEntities(
				post?.acf.introduction.replace(/<\/?[^>]+(>|$)/g, '') || '',
			)}
		>
			<>
				<p
					className={styles.introduction}
					dangerouslySetInnerHTML={{
						__html: decodeEntities(post?.acf.introduction || ''),
					}}
				/>

				<TextBlock
					className={styles.contexte}
					title={t('contexte')}
					content={{
						html: decodeEntities(post?.acf.contexte || ''),
					}}
				/>

				<TextBlock
					className={styles.projet}
					title={t('projet')}
					content={{
						className: styles.projetText,
						html: decodeEntities(post?.acf.projet || ''),
					}}
				/>

				<div className={styles.aside}>
					<h3 className={styles.asideTitle}>{t('details-projet')}</h3>
					<dl className={styles.details}>
						{post?.acf['details-projet'].map(({ libelle, valeur }, i) => (
							<Fragment key={i}>
								<dt>{libelle + '\u202f'}: </dt>
								<dd>{valeur}</dd>
							</Fragment>
						))}
					</dl>

					<h3 className={styles.asideTitle + ' ' + styles.chiffresClésTitle}>
						{t('chiffres-clés')}
					</h3>
					<ul className={styles.chiffresClés}>
						{post?.acf['chiffres-cles'].map(({ libelle, valeur }, i) => (
							<li key={i}>
								<strong>{frenchify(valeur)}</strong> {frenchify(libelle)}
							</li>
						))}
					</ul>
				</div>

				<BigTitle size="medium" tag="h2" className={styles.actionsTitle}>
					{t('actions')}
				</BigTitle>

				<TextBlock
					className={styles.actions}
					title={t('actions-subtitle')}
					content={{
						className: styles.actionsText,
						html: decodeEntities(post?.acf.actions || ''),
					}}
					link={{ to: routes.carte, children: t('actions-cta') }}
				/>

				<Contact
					type="full"
					nom={post?.acf.contact || ''}
					className={styles.fullContact}
				/>

				<div className={styles.greyBackground} />
			</>
		</WrapperActuEtude>
	) : (
		<PageNotFound />
	);
}

export default memo(EtudeDeCas, (prev, next) => {
	return prev.params.slug === next.params.slug;
});
