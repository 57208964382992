import React, { Fragment } from 'react';
import { getSizes, getSrcSet } from '../../../utils/img';
import Square from '../Square/Square';
import styles from './Nuages.module.scss';

const types = [
	[240, 480],
	[220, 440],
	[200, 400],
	[150, 300],
	[160, 320],
];

interface NuageProps {
	y: number;
	w: number;
	delay: number;
	type: 1 | 2 | 3 | 4 | 5;
}

const nuages: NuageProps[] = [
	// vision
	{ type: 2, y: 50, w: 9, delay: -25 },
	{ type: 5, y: 57, w: 8, delay: -20 },
	{ type: 3, y: 44, w: 9, delay: -18 },
	{ type: 1, y: 40, w: 6, delay: -34 },
	{ type: 2, y: 56, w: 8, delay: -7 },
	{ type: 3, y: 42, w: 6, delay: -6 },

	// persona
	{ type: 1, y: 28, w: 9, delay: -9 },
	{ type: 3, y: 73, w: 7, delay: -40 },
	{ type: 2, y: 78, w: 8.5, delay: 2 },

	// international
	{ type: 2, y: 76, w: 5, delay: 8 },
	{ type: 4, y: 77, w: 4, delay: -60 },
	{ type: 4, y: 83, w: 6, delay: -50 },
	{ type: 1, y: 87, w: 7, delay: -70 },
	{ type: 2, y: 6, w: 9, delay: -40 },
];

function Nuages() {
	return (
		<Square className={styles.wrapper}>
			{nuages.map((nuage, i) => (
				<Fragment key={i}>
					<Nuage {...nuage} />
					<Nuage {...nuage} shadow />
				</Fragment>
			))}
		</Square>
	);
}

function Nuage(props: NuageProps & { shadow?: boolean }) {
	return (
		<img
			alt=""
			className={[styles.nuage, props.shadow && styles.shadow]
				.filter(Boolean)
				.join(' ')}
			{...getSrcSet({
				ext: 'png',
				widths: types[props.type - 1],
				src: `/img/home/nuages/nuage${props.type}`,
			})}
			sizes={getSizes({
				'<=tablet': '100px',
				'<=laptop': '135px',
				'<=laptopL': '200px',
				default: '220px',
			})}
			style={{
				top: props.y + '%',
				['--w' as any]: props.w,
				animationDelay: props.delay + 's',
				animationDuration: (10 - props.w) * 20 + 's',
			}}
		/>
	);
}

export default Nuages;
