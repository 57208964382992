import { Lang } from '../../types';
import { WordPressPostType } from './types';

// const host = window.location.host.replace(
// 	'localhost:3000',
// 	'2020.preprod.h3p.com',
// );
// const wordPressEndpoint = `https://admin.${host}/wp-json/wp/v2`;
const wordPressEndpoint = `https://admin.h3p.com/wp-json/wp/v2`;

interface GetWordPressPostsArgs {
	lang?: Lang;
	page: number;
	limit: number;
	secteur?: string;
	type: WordPressPostType;
}

export async function getWordPressPosts<T>({
	lang,
	page,
	type,
	limit,
	secteur,
}: GetWordPressPostsArgs) {
	let endpoint = `/${type}?per_page=${limit}&page=${page}`;

	if (lang) endpoint += `&filter[lang]=${lang}`;

	if (secteur) {
		endpoint += `&filter[meta_key]=secteur&filter[meta_value]=${secteur}`;
	}

	const res = await fetch(wordPressEndpoint + endpoint);
	const json = await res.json();
	const total = +res.headers.get('X-WP-Total')!;

	if (json.code) console.error(json);
	return { posts: json as T[], total };
}

interface GetWordPressPostBySlugArgs {
	lang: Lang;
	slug: string;
	type: WordPressPostType;
}

export async function getWordPressPostBySlug<T>({
	slug,
	lang,
	type,
}: GetWordPressPostBySlugArgs) {
	let endpoint = `/${type}?slug=${slug}&filter[lang]=${lang}`;

	const res = await fetch(wordPressEndpoint + endpoint);
	const json = await res.json();
	if (json.code) console.error(json);
	return json as [T] | [];
}

export async function searchWordPressPosts(search: string) {
	let endpoint = `/search?search=${search}&_fields=title+url+subtype`;

	const res = await fetch(wordPressEndpoint + endpoint);
	const json = await res.json();
	if (json.code) console.error(json);

	return json.map((p: { title: string; url: string; subtype: string }) => {
		const type = p.url.match(/h3p\.com\/(fr|en)\/(?<type>.+?)s\/.+/)?.groups
			?.type;
		const url = p.url.match(/h3p\.com(?<url>\/.+)/)?.groups?.url;
		return { title: p.title, type, url };
	}) as { title: string; type: string; url: string }[];
}
