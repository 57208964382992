import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { orcomURL } from '../../../routes';
import BigTitle from '../../BigTitle/BigTitle';
import Illustration from '../../Illustration/Illustration';
import { illustrations } from '../../Illustration/illustrations';
import Img from '../../Img/Img';
import Quotation from '../../Quotation/Quotation';
import TextBlock from '../../TextBlock/TextBlock';
import styles from './Engagement.module.scss';

function Engagement() {
	const { t } = useTranslation('vision');

	return (
		<article id="engagement" className={styles.wrapper}>
			<BigTitle tag="h1" size="big" className={styles.title}>
				{t('vision:engagement.title')}
			</BigTitle>

			<Illustration
				parallax
				className={styles.img}
				{...illustrations.engagement}
				alt={t('vision:engagement.block1.title')}
			/>

			<TextBlock
				className={styles.block1}
				title={t('engagement.block1.title')}
				content={<Trans i18nKey="vision:engagement.block1.content" />}
			/>

			<Quotation
				className={styles.quotation}
				content={t('vision:engagement.quotation.content')}
				author={t('vision:engagement.quotation.author')}
			/>

			<TextBlock
				className={styles.block2}
				title={t('engagement.block2.title')}
				content={<Trans i18nKey="vision:engagement.block2.content" />}
			/>

			<Img
				parallax
				width={320}
				height={215}
				alt="Abbaye Royale de Moncel - Oise"
				src="/img/vision/engagement/abbaye-royale-moncel-oise"
				widths={[320, 512, 640, 1024]}
				sizes={{ '<laptop': '83vw', default: '35vw' }}
				className={styles.photo1}
			/>

			<TextBlock
				className={styles.block3}
				title={t('engagement.block3.title')}
				content={<Trans i18nKey="vision:engagement.block3.content" />}
			/>

			<Img
				width={250}
				height={330}
				alt="Escalade"
				parallax={-75}
				widths={[250, 500, 750]}
				className={styles.photo2}
				src="/img/vision/engagement/escalade"
				sizes={{ '<laptop': '46vw', default: '16vw' }}
			/>

			<TextBlock
				className={styles.block4}
				title={t('engagement.block4.title')}
				content={
					<Trans i18nKey="vision:engagement.block4.content">
						0
						<a href={orcomURL} target="_blank" rel="noopener noreferrer">
							1
						</a>
						2
					</Trans>
				}
			/>

			<Img
				width={330}
				height={328}
				parallax={-125}
				alt="Présentation"
				widths={[330, 660]}
				className={styles.photo3}
				src="/img/vision/engagement/presentation"
				sizes={{ '<laptop': '58vw', default: '22vw' }}
			/>
		</article>
	);
}

export default Engagement;
