import React, { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import routes from '../../routes';
import { Lang } from '../../types';
import CallToAction from '../CallToAction/CallToAction';
import styles from './CookiesConsent.module.scss';

function CookiesConsent() {
	const { t, ready, i18n } = useTranslation();

	const [visible, setVisible] = useState(() => {
		const lastAcceptedDate = +(localStorage.getItem('cookiesAccepted') || '');
		const now = new Date().getTime();
		return !lastAcceptedDate || lastAcceptedDate < now - 34160000000; // 13 mois
	});

	function accept() {
		localStorage.setItem('cookiesAccepted', new Date().getTime().toString());
		setVisible(false);
	}

	return ready ? (
		<CSSTransition
			appear
			in={visible}
			timeout={300}
			unmountOnExit
			classNames={styles}
		>
			<div className={styles.wrapper}>
				<p className={styles.text}>
					{t('cookies.text')}{' '}
					<Link
						onClick={accept}
						className={styles.link}
						to={routes['mentions-légales'][i18n.language as Lang]}
					>
						{t('cookies.moreInfo')}
					</Link>
				</p>

				<button onClick={accept}>
					<CallToAction>{t('cookies.ok')}</CallToAction>
				</button>
			</div>
		</CSSTransition>
	) : null;
}

export default memo(CookiesConsent);
