import React from 'react';
import { useTranslation } from 'react-i18next';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import CallToAction from '../../CallToAction/CallToAction';
import { Expertise } from '../Expertises';
import styles from './ExpertiseBlocks.module.scss';

interface ExpertiseBlocksProps {
	className: string;
	expertise: Expertise;
	openPopin: () => void;
}

function ExpertiseBlocks({
	className,
	expertise,
	openPopin,
}: ExpertiseBlocksProps) {
	const { t } = useTranslation('expertises');

	return (
		<TransitionGroup
			component="section"
			className={[styles.wrapper, className].join(' ')}
		>
			<CSSTransition
				key={expertise.title}
				mountOnEnter
				unmountOnExit
				classNames={styles}
				timeout={{ exit: 1000 }}
			>
				<div className={styles.block}>
					<h2 className={styles.title}>{expertise.title}</h2>

					<p className={styles.content}>
						{expertise.content}
						<br />

						{!!expertise.solutions.length && (
							<button className={styles.cta} onClick={openPopin}>
								<CallToAction>{t('solutionsCTA')}</CallToAction>
							</button>
						)}
					</p>
				</div>
			</CSSTransition>
		</TransitionGroup>
	);
}

export default ExpertiseBlocks;
