import React from 'react';
import { useTranslation } from 'react-i18next';
import routes from '../../../routes';
import { ProfilTypes } from '../../../routes/persona';
import CallToAction from '../../CallToAction/CallToAction';
import Link from '../../Link/Link';
import styles from './Expertises.module.scss';

interface ExpertisesProps {
	profil: ProfilTypes;
	className: string;
}

function Expertises({ profil, className }: ExpertisesProps) {
	const { t } = useTranslation('persona');

	const expertises: string[] = t(`${profil}.expertises`, {
		returnObjects: true,
		defaultValue: [],
	});

	return (
		<div className={[styles.wrapper, className].join(' ')}>
			<h2 className={styles.title}>{t('expertises.title')}</h2>

			<ul className={styles.list}>
				{expertises.map((expertise, i) => (
					<li key={i}>{expertise}</li>
				))}
			</ul>

			<Link to={routes.expertises}>
				<CallToAction>{t('expertises.cta')}</CallToAction>
			</Link>
		</div>
	);
}

export default Expertises;
