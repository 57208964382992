import React from 'react';
import Arbre from '../Arbre/Arbre';
import Fond from '../Fond/Fond';
import SlideImg from '../SlideImg/SlideImg';
import Square from '../Square/Square';
import styles from './International.module.scss';
import Pales from './Pales/Pales';

interface InternationalProps {
	visible: boolean;
}

function International({ visible }: InternationalProps) {
	return (
		<>
			<Fond dir="international" visible={visible} />

			<Square
				className={[styles.square, !visible && 'slide-hidden']
					.filter(Boolean)
					.join(' ')}
			>
				<SlideImg
					x={0}
					y={5}
					width={85}
					widths={[2156]}
					className={styles.vagues}
					src="/img/home/international/vagues/vagues"
				/>

				<SlideImg
					x={0}
					y={0}
					width={80}
					widths={[900, 1800, 1280, 330, 660]}
					src="/img/home/international/iles/iles"
				/>

				<SlideImg
					x={58.5}
					y={4}
					width={15}
					widths={[120, 384]}
					src="/img/home/international/fleuve/fleuve"
				/>

				<SlideImg
					x={-20}
					y={67}
					width={10}
					widths={[275]}
					src="/img/home/international/barques/barques"
				/>

				<SlideImg
					x={-48}
					y={19}
					width={5}
					widths={[145]}
					className={styles.voilier}
					src="/img/home/international/voilier/voilier"
				/>

				<SlideImg
					x={-61}
					y={21}
					width={3}
					widths={[89]}
					className={styles.aileron}
					src="/img/home/international/aileron/aileron"
				/>

				<SlideImg
					x={-52.4}
					y={-23.5}
					width={9.1}
					widths={[238]}
					src="/img/home/international/fenetres/fenetres"
				/>

				<SlideImg
					x={37}
					y={-49}
					width={3}
					widths={[87]}
					src="/img/home/international/volcan/volcan"
				/>

				<Pales x={-14.3} y={-33} />
				<Pales x={-25.1} y={-37} />
				<Pales x={-35.6} y={-39.5} />
				<Pales x={-41.5} y={-38.2} />

				<Arbre type={4} x={44} y={-2} />
				<Arbre type={4} x={48.2} y={-3.7} />
				<Arbre type={4} x={64.4} y={9.7} />
				<Arbre type={4} x={67} y={13.5} />

				<Arbre type={5} x={35.7} y={-24.7} />
				<Arbre type={5} x={39.7} y={-24} />
				<Arbre type={5} x={46} y={-18.5} />
				<Arbre type={5} x={41} y={5} />
				<Arbre type={5} x={50.5} y={15.7} />

				<Arbre type={6} x={-52.2} y={-9} />
				<Arbre type={6} x={-11.6} y={-17} />

				<Arbre type={7} x={-35} y={-29} />
				<Arbre type={7} x={-38.3} y={-27} />
				<Arbre type={7} x={-47} y={-13} />
				<Arbre type={7} x={-67} y={-3.6} />
				<Arbre type={7} x={-70.8} y={-1.6} />

				<Arbre type={8} x={-17.5} y={42} />
				<Arbre type={8} x={-2.5} y={47} />
				<Arbre type={8} x={7.5} y={47} />
				<Arbre type={8} x={37} y={57} />
				<Arbre type={8} x={2} y={60} />

				<Arbre type={9} x={-26} y={49.6} />
				<Arbre type={9} x={-39} y={56.5} />
				<Arbre type={9} x={-44} y={57.2} />

				<div className={styles.avion}>
					<SlideImg
						x={0}
						y={-6}
						width={5.8}
						widths={[147]}
						src="/img/home/international/avion/avion"
					/>
				</div>
				<div className={styles.ombreAvion}>
					<SlideImg
						x={17}
						y={40}
						width={5.8}
						widths={[147]}
						src="/img/home/international/avion/avion"
					/>
				</div>
			</Square>
		</>
	);
}

export default International;
