interface TeamMember {
	name: string;
	slug: string;
	email?: string;
	telephone?: string;
	linkedIn?: string;
	post: 'président' | 'directeur-général' | 'directeur' | 'manager' | 'associé';
}

export const team: TeamMember[] = [
	// {
	// 	name: 'Éric Hinderer',
	// 	slug: 'eric-hinderer',
	// 	post: 'président',
	// 	linkedIn: 'eric-hinderer-72257913',
	// },
	// {
	// 	name: 'Jean-Baptiste Colin',
	// 	slug: 'jean-baptiste-colin',
	// 	post: 'associé',
	// 	email: 'jean-baptiste.colin@h3p.com',
	// 	telephone: '01 85 78 09 04',
	// 	linkedIn: 'jean-baptiste-colin-28939125',
	// },
	{
		name: 'Jeremy Vlach',
		slug: 'jeremy-vlach',
		post: 'associé',
		email: 'jeremy.vlach@h3p.com',
		telephone: '01 85 78 09 04',
		linkedIn: 'jeremy-vlach',
	},
	{
		name: 'Benjamin Lepesant',
		slug: 'benjamin-lepesant',
		post: 'associé',
		email: 'benjamin.lepesant@h3p.com',
		telephone: '01 85 78 09 03',
		linkedIn: 'benjamin-lepesant-876b441b',
	},
	// {
	// 	name: 'Jean-Benoit Monnais',
	// 	slug: 'jean-benoit-monnais',
	// 	post: 'associé',
	// 	linkedIn: 'jean-benoit-monnais-7998081',
	// },
	{
		name: 'Christophe Knockaert',
		slug: 'christophe-knockaert',
		post: 'associé',
		linkedIn: 'christophe-knockaert-133a6b',
	},
	{
		name: 'Olivier Kiritchenko',
		slug: 'olivier-kiritchenko',
		post: 'associé',
		linkedIn: 'olivier-kiritchenko-2535793',
	},
	// {
	// 	name: 'Jérémy Vlach',
	// 	slug: 'jérémy-vlach',
	// 	post: 'directeur',
	// 	linkedIn: 'jeremy-vlach',
	// },
	// {
	// 	name: 'Flavio Matos',
	// 	slug: 'flavio-matos',
	// 	post: 'directeur',
	// },
	{
		name: 'Laurent Cotte',
		slug: 'laurent-cotte',
		post: 'manager',
		linkedIn: 'laurent-cotte-a55591198',
	},
	// {
	// 	name: 'Laurence Tomas',
	// 	slug: 'laurence-tomas',
	// 	post: 'manager',
	// },
	{
		name: 'Anne-Lise Debieve',
		slug: 'anne-lise-debieve',
		post: 'manager',
	},
	// {
	// 	name: 'Reda Bendaou',
	// 	slug: 'reda-bendaou',
	// 	post: 'manager',
	// 	linkedIn: 'redabendaou',
	// },
	{
		name: 'Apollin de Bardin',
		slug: 'apollin-de-bardin',
		post: 'manager',
		linkedIn: 'apollin-de-bardin-417647121',
	},
];
