import React from 'react';
import { Option } from '../Filtre/Filtre';
import styles from './FiltreRadio.module.scss';

interface FiltreRadioProps {
	current: Option;
	options: Option[];
	className: string;
	setOption: (option: Option) => void;
}

function FiltreRadio({
	current,
	options,
	setOption,
	className,
}: FiltreRadioProps) {
	return (
		<ul className={[styles.wrapper, className].join(' ')}>
			{options.map(option => (
				<li key={option.value}>
					<button
						onClick={() => setOption(option)}
						className={[
							styles.item,
							current.value === option.value && styles.active,
						]
							.filter(Boolean)
							.join(' ')}
					>
						{option.label}
					</button>
				</li>
			))}
		</ul>
	);
}

export default FiltreRadio;
