import home from './home';
import persona from './persona';
import secteurs from './secteurs';

export type TranslatedRoute = { fr: string; en: string };

const routes = {
	home,
	secteurs,
	persona,

	splashScreen: {
		fr: '/fr',
		en: '/en',
	},
	vision: {
		fr: '/fr/vision/detail',
		en: '/en/vision/detail',
	} as TranslatedRoute,
	carte: {
		fr: '/fr/international/carte',
		en: '/en/international/map',
	} as TranslatedRoute,
	expertises: {
		en: '/en/expertises',
		fr: '/fr/expertises',
	} as TranslatedRoute,
	carrières: {
		en: '/en/career',
		fr: '/fr/carrieres',
	} as TranslatedRoute,
	actualités: {
		en: '/en/news',
		fr: '/fr/actualites',
	} as TranslatedRoute,
	actualité: {
		en: '/en/news/:slug',
		fr: '/fr/actualites/:slug',
	} as TranslatedRoute,
	étude: {
		en: '/en/studies/:slug',
		fr: '/fr/etudes/:slug',
	} as TranslatedRoute,
	offres: {
		en: '/en/offers/:slug',
		fr: '/fr/offres/:slug',
	} as TranslatedRoute,
	'mentions-légales': {
		en: '/en/legal-notice',
		fr: '/fr/mentions-legales',
	} as TranslatedRoute,
	404: {
		en: '/en/404',
		fr: '/fr/404',
	} as TranslatedRoute,
};

export default routes;

export const orcomURL = 'https://www.orcom.fr/';

function deepFlat(object: object): any {
	return 'fr' in object
		? object
		: Object.values(object).map((val) => deepFlat(val));
}
export const allRoutes: TranslatedRoute[] = deepFlat(routes).flat(Infinity);
export const homeRoutes: TranslatedRoute[] = deepFlat(routes.home).flat();
export const personaRoutes: TranslatedRoute[] = deepFlat(routes.persona).flat(
	Infinity,
);

export const firstSlide: TranslatedRoute = routes.home.persona;
