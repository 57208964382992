import React, { memo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import routes from '../../routes';
import BigText from '../BigText/BigText';
import BigTitle from '../BigTitle/BigTitle';
import Head from '../Head/Head';
import Illustration from '../Illustration/Illustration';
import { illustrations } from '../Illustration/illustrations';
import LoadingPage from '../LoadingPage/LoadingPage';
import TextBlock from '../TextBlock/TextBlock';
import styles from './Carrieres.module.scss';
import ListeOffres from './ListeOffres/ListeOffres';
import Metiers from './Metiers/Metiers';
import Social from './Social/Social';
import Temoignages from './Temoignages/Temoignages';
import Valeurs from './Valeurs/Valeurs';

function Carrieres() {
	const { t, ready } = useTranslation('carrieres');

	const lines = ready
		? (t('bigText', { returnObjects: true, defaultValue: [] }) as string[])
		: [];

	return ready ? (
		<section className={styles.wrapper}>
			<Head
				title={t('title')}
				description={t('description')}
				img="/img/opengraph/carrieres.png"
			/>

			<BigTitle size="big" tag="h1" className={styles.title} startsPage>
				{t('title')}
			</BigTitle>

			<Illustration
				alt={t('title')}
				{...illustrations.carrieres}
				className={styles.illustration}
			/>

			<TextBlock
				className={styles.block1}
				title={t('block1.title')}
				content={<Trans i18nKey={'carrieres:block1.content'} />}
				link={{ children: t('block1.cta'), to: routes.vision }}
			/>

			<TextBlock
				className={styles.block2}
				title={t('block2.title')}
				content={<Trans i18nKey={'carrieres:block2.content'} />}
				link={{ children: t('block2.cta'), to: routes.carte }}
			/>

			<Metiers className={styles.metiers} />

			<ListeOffres className={styles.offres} />

			<Valeurs className={styles.valeurs} />

			<BigText className={styles.bigText}>
				{lines
					.map((line, i) =>
						line.split(' ').map((word) => ({ word, stroke: i === 1 })),
					)
					.flat()}
			</BigText>

			<Temoignages className={styles.temoignages} />

			<Social className={styles.social} />
		</section>
	) : (
		<LoadingPage />
	);
}

export default memo(Carrieres, () => true);
