import { passiveSupported } from './passiveSupported';

const keys = [37, 38, 39, 40];

function preventDefault(e: Event) {
	e.preventDefault();
}

function preventDefaultForScrollKeys(e: KeyboardEvent) {
	if (keys.includes(e.keyCode)) {
		preventDefault(e);
		return false;
	}
}

const options = passiveSupported ? { passive: false } : false;

export function disableScroll() {
	window.addEventListener('wheel', preventDefault, options);
	window.addEventListener('touchmove', preventDefault, options);
	window.addEventListener('keydown', preventDefaultForScrollKeys, options);
}

export function enableScroll() {
	window.removeEventListener('wheel', preventDefault);
	window.removeEventListener('touchmove', preventDefault);
	window.removeEventListener('keydown', preventDefaultForScrollKeys);
}
