import React, { useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import routes, { TranslatedRoute } from '../../routes';
import { WithAnimatedRouteProps } from '../AnimatedRoute/AnimatedRoute';
import BigTitle from '../BigTitle/BigTitle';
import Head from '../Head/Head';
import { PersonaContext } from '../ModulePersona/ModulePersona';
import TextBlock from '../TextBlock/TextBlock';
import styles from './Home.module.scss';
import LogoOrcom from './LogoOrcom/LogoOrcom';
import Switch from './Switch/Switch';

export const transitionDuration = 1000;

const pages: {
	namespace: keyof typeof routes.home;
	slide: TranslatedRoute;
	link?: TranslatedRoute;
	openPopin?: true;
}[] = [
	{
		namespace: 'persona',
		slide: routes.home.persona,
		openPopin: true,
	},
	{
		namespace: 'vision',
		slide: routes.home.vision,
		link: routes.vision,
	},
	{
		namespace: 'international',
		slide: routes.home.international,
		link: routes.carte,
	},
];

function Home({ url }: WithAnimatedRouteProps) {
	const { t, ready } = useTranslation('home');

	const { key } = useLocation();

	const { open: setPopinPersonaOpen } = useContext(PersonaContext);

	const current = pages.find((p) => [p.slide.en, p.slide.fr].includes(url))!;

	const pageRef = useRef<HTMLDivElement>(null);

	return ready ? (
		<section ref={pageRef} className={styles.wrapper}>
			<Head
				title={t(current.namespace + '.title')}
				description={t(current.namespace + '.description')}
				img="/img/opengraph/vision.png"
			/>

			<SwitchTransition mode="out-in">
				<CSSTransition
					key={key}
					unmountOnExit
					timeout={{ exit: transitionDuration }}
				>
					<BigTitle tag="h1" size="small" className={styles.title} startsPage>
						{t(current.namespace + '.title')}
					</BigTitle>
				</CSSTransition>
			</SwitchTransition>

			<SwitchTransition mode="out-in">
				<CSSTransition
					key={key}
					unmountOnExit
					timeout={{ exit: transitionDuration }}
				>
					<TextBlock
						className={styles.description}
						content={ready && t(current.namespace + '.description')}
						link={
							current.link && {
								to: current.link,
								children: t(current.namespace + '.cta'),
								animated: true,
							}
						}
						button={
							current.openPopin && {
								onClick: setPopinPersonaOpen,
								children: t(current.namespace + '.cta'),
								animated: true,
							}
						}
					/>
				</CSSTransition>
			</SwitchTransition>

			<LogoOrcom className={styles.logoOrcom} />

			<Switch pageRef={pageRef} className={styles.sliderSwitch} />
		</section>
	) : null;
}

export default Home;
