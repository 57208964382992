import React, { ComponentType, useEffect, useRef } from 'react';
import {
	Route,
	RouteComponentProps,
	RouteProps,
	useLocation,
} from 'react-router';
import { CSSTransition } from 'react-transition-group';
import { disableScroll, enableScroll } from '../../utils/scroll';
import ScrollToTop from '../ScrollToTop/ScrollToTop';
import styles from './AnimatedRoute.module.scss';

export const pageTransitionDuration = 1000;

function scrollTo(hash: string) {
	const element = document.querySelector('.page-enter-done ' + hash);
	return element ? element.scrollIntoView() : false;
}

export type WithAnimatedRouteProps<T = {}> = Pick<
	RouteComponentProps<T>['match'],
	'params' | 'url'
>;

type Props = Partial<Pick<RouteComponentProps['match'], 'params' | 'url'>> & {
	active: boolean;
	path: RouteProps['path'];
	component: ComponentType<any>;
	withScrollToTop?: boolean;
};

function MemorizedRoute(
	props: Pick<
		Props,
		'active' | 'component' | 'params' | 'url' | 'withScrollToTop'
	>,
) {
	const Component = props.component;
	const params = useRef(props.params);
	const url = useRef(props.url);
	const firsMount = useRef(true);
	const { hash } = useLocation();

	// Mémorisation des props au chgt de route
	useEffect(() => {
		if (props.active) {
			url.current = props.url;
			params.current = props.params;
		}
	});

	// Scroll to hash
	useEffect(() => {
		if (!hash || !props.active) return;

		// On essaie de scroll direct, sinon on attend la transition de page et on réessaie
		if (scrollTo(hash) === false) {
			setTimeout(() => scrollTo(hash), pageTransitionDuration + 100);
		}
	}, [hash, props.active]);

	// Scroll to top au changement de page
	useEffect(() => {
		if (firsMount.current) {
			firsMount.current = false;
			return;
		}
		if (!props.active) return;

		disableScroll();
		document.documentElement.style.scrollBehavior = 'auto';

		setTimeout(() => {
			window.scrollTo(0, 0);
			document.documentElement.style.scrollBehavior = 'smooth';
			enableScroll();
		}, pageTransitionDuration);
	}, [props.active]);

	return (
		<CSSTransition
			appear
			mountOnEnter
			unmountOnExit
			in={props.active}
			classNames={'page'}
			timeout={{
				appear: 200, // nécessaire pour que la classe "page-appear" ait le temps de s'appliquer avant de disparaître
				enter: pageTransitionDuration,
				exit: pageTransitionDuration,
			}}
		>
			<main className={styles.wrapper}>
				<Component
					url={props.url || url.current}
					params={props.params || params.current}
				/>

				{props.withScrollToTop && <ScrollToTop />}
			</main>
		</CSSTransition>
	);
}

function AnimatedRoute(
	props: Pick<Props, 'path' | 'component' | 'withScrollToTop'>,
) {
	return (
		<Route exact path={props.path} strict>
			{({ match }) => (
				<MemorizedRoute
					active={!!match}
					url={match?.url}
					params={match?.params}
					component={props.component}
					withScrollToTop={props.withScrollToTop}
				/>
			)}
		</Route>
	);
}

export default AnimatedRoute;
