import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './BtnPostuler.module.scss';

interface BtnPostulerProps {
	className: string;
	title: string;
}

function BtnPostuler({ className, title }: BtnPostulerProps) {
	const { t } = useTranslation('offre');

	const subject = encodeURIComponent(t('email.subject') + title);
	const body = [
		t('email.bonjour'),
		'',
		`${t('email.je-vous-soumets')} "${title}" (${window.location}).`,
		t('email.veuillez-trouver'),
		'',
		t('email.cordialement'),
	]
		.map(encodeURIComponent)
		.join('%0D%0A');

	return (
		<a
			target="_blank"
			rel="noopener noreferrer"
			className={[styles.wrapper, className].join(' ')}
			href={`mailto:jobs@h3p.com?subject=${subject}&body=${body}`}
		>
			{t('postuler')}
			<span className={styles.arrow} />
		</a>
	);
}

export default BtnPostuler;
