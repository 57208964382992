import { decodeEntities } from '@wordpress/html-entities';
import React from 'react';
import { Link } from 'react-router-dom';
import { Result } from '../ModuleRecherche';
import styles from './Liste.module.scss';

interface ListeProps {
	title: string;
	list: Result[];
	onLinkClick: () => void;
}

function Liste({ title, list, onLinkClick }: ListeProps) {
	return list.length ? (
		<>
			<p className={styles.title}>{title}</p>

			<ul className={styles.list}>
				{list.map(({ title, url }, i) => (
					<li key={i} className={styles.item}>
						<Link
							to={url}
							title={title}
							className={styles.link}
							onClick={onLinkClick}
						>
							{decodeEntities(title)}
						</Link>
					</li>
				))}
			</ul>
		</>
	) : null;
}

export default Liste;
