import React from 'react';
import { media } from '../../../utils/breakpoints/breakpoints';
import { getSrcSet } from '../../../utils/img';
import { slideImgWidth } from '../SlideImg/SlideImg';
import s from '../Slider.module.scss';
import styles from './Fond.module.scss';

const widths = [980, 1960, 1500, 1380, 380, 760];

interface FondProps {
	visible: boolean;
	dir: 'persona' | 'vision' | 'international';
}

function Fond({ dir, visible }: FondProps) {
	const src = `/img/home/${dir}/fond/fond`;
	const sizes = `${media['<tablet']} ${slideImgWidth.mobile}vw, ${s.canvasHeight}`;

	return (
		<img
			alt=""
			sizes={sizes}
			{...getSrcSet({ ext: 'png', src, widths: widths })}
			className={[styles.fond, !visible && styles.hidden]
				.filter(Boolean)
				.join(' ')}
		/>
	);
}

export default Fond;
