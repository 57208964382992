export const allClients = [
	'3i',
	'A150 ALBEA',
	'Alicorne',
	'Alstom',
	'Altitude Infrastructure',
	'Amundi',
	'Atlandes',
	'Atream',
	'Axione',
	'Bam PPP',
	'Banque des territoires caisse des dépots',
	'Bouygues Construction',
	'Bouygues',
	'BPI France',
	'Caisse des Dépôts Groupe',
	'CDC Habitat',
	'Ceetrus',
	'Colas',
	'Commission de régulation de l’énergie',
	'Demathieu Bard',
	'Demeter',
	'DIF',
	'EDF',
	'EDF Pulse',
	'Eiffage',
	'Engie',
	'Fayat',
	'GCC',
	'GE Real Estate',
	'Gedia',
	'Groupe Duval',
	'Icade',
	'Icamap',
	'Iliad',
	'Infravia',
	'Ivanhoé Cambridge',
	'Keolis',
	'Kouros Investment',
	'LISEA',
	'Losange Fibre',
	'Malakoff Médéric Humanis',
	'Marguerite',
	'Meridiam',
	'Mirova',
	'Neoen',
	'NGE',
	'Orange',
	'Ostwind',
	'Quaero Capital',
	'Qucit',
	'Qwant',
	'RATP',
	'RATPDev',
	'Rhonexpress',
	'Rosace Fibre',
	'Sade',
	'Schroders',
	'Spallian',
	'Spie Batignolles',
	'TDF',
	'Total Eren',
	'Urban Premium',
	'Urbasolar',
	'VIIA',
	'VINCI Airports',
	'VINCI Concessions',
	'Vinci',
	'Vivalto Home',
	'Voltalia',
] as const;

export const pngClients: typeof allClients[number][] = [
	'A150 ALBEA',
	'Icade',
	'Infravia',
	'Marguerite',
	'Rosace Fibre',
	'Total Eren',
	'Urban Premium',
	'VIIA',
	'Atlandes',
	'EDF Pulse',
];
