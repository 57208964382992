import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Lang } from '../../../types';
import { getWordPressPosts } from '../../../utils/wordpress';
import { WordPressOffre } from '../../../utils/wordpress/types';
import BigTitle from '../../BigTitle/BigTitle';
import CallToAction from '../../CallToAction/CallToAction';
import VignetteOffre from '../VignetteOffre/VignetteOffre';
import styles from './ListeOffres.module.scss';

const max = 4;

interface ListeOffresProps {
	className: string;
	excludeId?: number;
}

function ListeOffres({ className, excludeId }: ListeOffresProps) {
	const { t, ready, i18n } = useTranslation('carrieres');

	const [posts, setPosts] = useState<WordPressOffre[]>([]);
	const [loading, setLoading] = useState(true);
	const [total, setTotal] = useState(0);
	const [limit, setLimit] = useState(max);

	const fetchPosts = useCallback(
		async function () {
			const { posts, total } = await getWordPressPosts<WordPressOffre>({
				limit,
				page: 1,
				type: 'offre',
				lang: i18n.language as Lang,
			});
			return { offres: posts, total };
		},
		[i18n.language, limit],
	);

	useEffect(() => {
		let isMounted = true;
		setLoading(true);
		fetchPosts()
			.then(({ offres, total }) => {
				if (!isMounted) return;
				setPosts(offres);
				setTotal(total);
			})
			.catch((err) => isMounted && console.error(err.message))
			.finally(() => isMounted && setLoading(false));

		return () => {
			isMounted = false;
		};
	}, [fetchPosts]);

	return ready ? (
		<section className={[styles.wrapper, className].join(' ')}>
			<BigTitle size="small" tag="h2" className={styles.title}>
				{total + ' ' + t('offres.title').replace(total === 1 ? 's' : '', '')}
			</BigTitle>

			<ul className={styles.list}>
				{loading
					? [...new Array(limit === max ? max - 1 : 9)].map((_, i) => (
							<li key={i} className={styles.item}>
								<VignetteOffre />
							</li>
					  ))
					: posts
							.filter((post) => post.id !== excludeId)
							.map((post) => (
								<li key={post.id} className={styles.item}>
									<VignetteOffre
										slug={post.slug}
										title={post.title.rendered}
										content={post.acf.missions}
										type={post.acf['type-offre']}
										localisation={post.acf.localisation}
									/>
								</li>
							))
							.slice(0, limit === max ? 3 : undefined)}
			</ul>

			{limit === max && (
				<button className={styles.cta} onClick={() => setLimit(100)}>
					<CallToAction>{t('offres.cta')}</CallToAction>
				</button>
			)}
		</section>
	) : null;
}

export default ListeOffres;
