import React from 'react';
import { Parallax, useController } from 'react-scroll-parallax';
import { getSizes, getSrcSet, ImgSizes } from '../../utils/img';
import styles from './Img.module.scss';

export interface Props {
	alt: string;
	className?: string;
	imgClassName?: string;
	src: string;
	png?: boolean;
	/** positive = faster than scroll, negative = slower, default -100 */
	parallax?: boolean | number;
	/** first width is the default width */
	widths: number[];
	width: number;
	height: number;
	sizes: ImgSizes;
}

function Img(props: Props) {
	const fallback = props.png ? 'png' : 'jpg';

	const sizes = getSizes(props.sizes);

	const { parallaxController } = useController();

	const srcWidths = { src: props.src, widths: props.widths };

	const { srcSet: srcSetWebP, src: srcWebP } = getSrcSet({
		ext: 'webp',
		...srcWidths,
	});
	const { srcSet: srcSetFallBack, src: srcFallBack } = getSrcSet({
		ext: fallback,
		...srcWidths,
	});

	return (
		<Parallax
			tagInner="div"
			tagOuter="figure"
			disabled={!props.parallax}
			className={[styles.figure, props.className].filter(Boolean).join(' ')}
			y={
				typeof props.parallax === 'number'
					? [props.parallax + 'px', props.parallax * -1 + 'px']
					: ['-100px', '100px']
			}
		>
			<picture>
				<source
					sizes={sizes}
					src={srcWebP}
					type="image/webp"
					srcSet={srcSetWebP}
				/>
				<img
					sizes={sizes}
					loading="lazy"
					alt={props.alt}
					src={srcFallBack}
					width={props.width}
					height={props.height}
					srcSet={srcSetFallBack}
					onLoad={() => props.parallax && parallaxController.update()}
					className={[styles.img, props.imgClassName].filter(Boolean).join(' ')}
				/>
			</picture>
		</Parallax>
	);
}

export default Img;
