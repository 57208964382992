import React, {
	RefObject,
	useCallback,
	useEffect,
	useRef,
	useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { useWheelTouchAndKeyboard } from '../../../hooks/useWheelTouchAndKeyboard';
import routes from '../../../routes';
import { Lang } from '../../../types';
import Link from '../../Link/Link';
import { transitionDuration } from '../Home';
import styles from './Switch.module.scss';

const pages = [
	routes.home.persona,
	routes.home.vision,
	routes.home.international,
];
const length = pages.length;

interface SwitchProps {
	className: string;
	pageRef: RefObject<HTMLDivElement>;
}

function Switch({ pageRef, className }: SwitchProps) {
	const { pathname } = useLocation();
	const history = useHistory();
	const { i18n } = useTranslation();

	const [isTransiting, setTransiting] = useState(false);

	const hasScrolled = useRef(false);

	const index = pages.findIndex((p) => [p.en, p.fr].includes(pathname));
	const [oldIndex, setOldIndex] = useState(index);

	const getNextRoute = useCallback(
		(delta: 1 | -1) => pages[(index + delta + length) % length],
		[index],
	);

	const goTo = useCallback(
		(direction) => {
			if (isTransiting || hasScrolled.current || index === -1) return;
			hasScrolled.current = true;
			history.push(getNextRoute(direction)[i18n.language as Lang]);
		},
		[isTransiting, history, i18n.language, index, getNextRoute],
	);

	useWheelTouchAndKeyboard({ area: pageRef, goTo, preventScroll: true });

	useEffect(() => {
		let isMounted = true;

		if (index > -1) setOldIndex(index);
		setTransiting(true);
		setTimeout(() => {
			if (!isMounted) return;
			hasScrolled.current = false;
			setTransiting(false);
		}, transitionDuration + 100);

		return () => {
			isMounted = false;
		};
	}, [index]);

	return (
		<div className={[styles.wrapper, className].join(' ')}>
			<Link
				to={getNextRoute(-1)}
				className={[styles.prev, isTransiting && styles.inactive]
					.filter(Boolean)
					.join(' ')}
			>
				<span className={styles.arrow} />
			</Link>

			<span className={styles.current}>0{oldIndex + 1}</span>

			<nav className={styles.nav}>
				{pages.map((p, i) => (
					<Link
						key={i}
						to={p}
						className={[styles.link, isTransiting && styles.inactive]
							.filter(Boolean)
							.join(' ')}
					>
						{' '}
					</Link>
				))}

				<span
					className={styles.line}
					style={{ transform: `translateY(${100 * oldIndex}%)` }}
				/>
			</nav>

			<span>03</span>

			<Link
				to={getNextRoute(1)}
				className={[styles.next, isTransiting && styles.inactive]
					.filter(Boolean)
					.join(' ')}
			>
				<span className={styles.arrow} />
			</Link>
		</div>
	);
}

export default Switch;
