import { decodeEntities } from '@wordpress/html-entities';
import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import {
	AcfImage,
	WordPressPostType,
	Yoast,
} from '../../utils/wordpress/types';
import BigTitle from '../BigTitle/BigTitle';
import BtnBack from '../BtnBack/BtnBack';
import Head from '../Head/Head';
import LoadingPage from '../LoadingPage/LoadingPage';
import WordPressImg from '../WordPressImg/WordPressImg';
import styles from './WrapperActuEtude.module.scss';

interface WrapperActuEtudeProps {
	children: ReactNode;
	title?: string;
	slug: string;
	loading: boolean;
	description?: string;
	type: WordPressPostType;
	image?: AcfImage;
	yoast?: Yoast;
}

function WrapperActuEtude({
	type,
	slug,
	title,
	yoast,
	image,
	loading,
	children,
	description,
}: WrapperActuEtudeProps) {
	const { t, ready } = useTranslation('actualites');

	return (
		<>
			<Head
				title={title || slug}
				description={description || ''}
				yoast={yoast}
			/>

			{loading && <LoadingPage />}

			<article className={styles.wrapper}>
				<p className={styles.actu}>{ready && t(`actualites:${type}`)}</p>

				<BtnBack type="actus" className={styles.btnBack} />

				{title && (
					<BigTitle size="small" tag="h1" className={styles.title} startsPage>
						{decodeEntities(title)}
					</BigTitle>
				)}

				{image && (
					<figure className={styles.photo}>
						<WordPressImg
							wpSizes={image.sizes}
							alt={image.alt || title}
							sizes={{ default: '54vw', '<laptopL': '48vw', '<laptop': '89vw' }}
						/>
					</figure>
				)}

				{children}
			</article>
		</>
	);
}

export default WrapperActuEtude;
