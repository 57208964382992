import React from 'react';
import { useTranslation } from 'react-i18next';
import { firstSlide } from '../../routes';
import BigText from '../BigText/BigText';
import CallToAction from '../CallToAction/CallToAction';
import Head from '../Head/Head';
import { ReactComponent as Logo } from '../Header/logo-h3p.svg';
import Link from '../Link/Link';
import LoadingPage from '../LoadingPage/LoadingPage';
import styles from './SplashScreen.module.scss';

function SplashScreen() {
	const { t, ready } = useTranslation(['common', 'home']);

	const titleArray = ready
		? (t('common:splashScreen', {
				defaultValue: [],
				returnObjects: true,
		  }) as string[])
		: [];

	return ready ? (
		<div className={styles.wrapper}>
			<Head
				title={titleArray.join(' ')}
				description={t('home:persona.description')}
				img="/img/opengraph/persona.png"
			/>

			<Logo className={styles.logo} />

			<p className={styles.text}>{t('common:headline')} </p>

			<Link to={firstSlide}>
				<BigText
					className={styles.bigText}
					children={titleArray.map((word, i) => ({ word, stroke: i === 1 }))}
				/>
			</Link>

			<Link to={firstSlide} className={styles.cta}>
				<CallToAction>{t('common:enter')}</CallToAction>
			</Link>
		</div>
	) : (
		<LoadingPage />
	);
}

export default SplashScreen;
