import React, { ComponentProps } from 'react';
import SlideImg from '../../SlideImg/SlideImg';
import styles from './Pales.module.scss';

type PalesProps = Pick<ComponentProps<typeof SlideImg>, 'x' | 'y'>;

function Pales({ x, y }: PalesProps) {
	return (
		<SlideImg
			x={x}
			y={y}
			width={2.4}
			widths={[63]}
			className={styles.pales}
			src="/img/home/international/pales/pales"
			style={{ animationDelay: x * y * -1 + 'ms' }}
		/>
	);
}

export default Pales;
