import React from 'react';
import { InView } from 'react-intersection-observer';
import styles from './BigText.module.scss';

interface Props {
	children: { word: string; stroke?: boolean }[];
	className: string;
	strokeClassName?: string;
	tag?: 'p' | 'h1' | 'h2' | 'h3';
}

function BigText({
	children,
	className,
	tag: Tag = 'p',
	strokeClassName,
}: Props) {
	return (
		<InView rootMargin="-150px 0px 0px" triggerOnce>
			{({ inView, ref }) => (
				<Tag
					ref={ref}
					className={[styles.line, className, inView && styles.inView]
						.filter(Boolean)
						.join(' ')}
				>
					{children.map((word, i) => (
						<Word
							key={i}
							index={i}
							{...word}
							strokeClassName={strokeClassName}
						/>
					))}
				</Tag>
			)}
		</InView>
	);
}

function Word({
	word,
	index,
	stroke,
	strokeClassName,
}: Props['children'][0] & { index: number; strokeClassName?: string }) {
	return (
		<>
			<span
				className={[
					styles.word,
					stroke && styles.stroke,
					stroke && strokeClassName,
				]
					.filter(Boolean)
					.join(' ')}
			>
				{word.split('').map((letter, i) => (
					<span key={i} className={styles.letterCache}>
						<span
							className={styles.letter}
							style={{ transitionDelay: index * 100 + i * 20 + 'ms' }}
						>
							{letter}
						</span>
					</span>
				))}
			</span>{' '}
		</>
	);
}

export default BigText;
