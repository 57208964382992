function truncate(str: string, length: number) {
	if (str.length <= length) {
		return str;
	}
	var subString = str.substr(0, length - 1);
	return subString.substr(0, subString.lastIndexOf(' ')) + '\u2026';
}

export function getExcerptFromString(string: string, length: number) {
	return truncate(string.replace(/(<([^>]+)>)/gi, ''), length);
}
