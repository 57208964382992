import React from 'react';
import Fond from '../Fond/Fond';
import SlideImg from '../SlideImg/SlideImg';
import Square from '../Square/Square';
import styles from './Vision.module.scss';

interface VisionProps {
	visible: boolean;
}

function Vision({ visible }: VisionProps) {
	return (
		<>
			<Fond dir="vision" visible={visible} />

			<Square className={styles.square}>
				<SlideImg
					x={0}
					y={10}
					width={35}
					widths={[310, 450, 500, 620]}
					src="/img/home/vision/cheval/cheval"
				/>

				<SlideImg
					x={14.3}
					y={-58.7}
					width={11}
					widths={[235, 120]}
					src="/img/home/vision/cravate/cravate"
				/>
			</Square>
		</>
	);
}

export default Vision;
