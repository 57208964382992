import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import BigText from '../BigText/BigText';
import CallToAction from '../CallToAction/CallToAction';
import { PersonaContext } from '../ModulePersona/ModulePersona';
import styles from './FooterPersona.module.scss';

function FooterPersona() {
	const { t } = useTranslation();

	const { open } = useContext(PersonaContext);

	const title = t('ctaPersona.title').split(' ');

	return (
		<div className={styles.wrapper}>
			<BigText className={styles.title} strokeClassName={styles.stroke}>
				{title.map((word, i) => ({ word, stroke: i === 1 }))}
			</BigText>

			<p className={styles.description}>{t('ctaPersona.description')}</p>

			<button onClick={open} className={styles.cta}>
				<CallToAction>{t('ctaPersona.button')}</CallToAction>
			</button>
		</div>
	);
}

export default FooterPersona;
