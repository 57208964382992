import React, { useEffect, useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import routes from '../../routes';
import { listPersonas } from '../../routes/persona';
import { media } from '../../utils/breakpoints/breakpoints';
import { WithAnimatedRouteProps } from '../AnimatedRoute/AnimatedRoute';
import BigTitle from '../BigTitle/BigTitle';
import Head from '../Head/Head';
import Illustration from '../Illustration/Illustration';
import { illustrations } from '../Illustration/illustrations';
import LoadingPage from '../LoadingPage/LoadingPage';
import Quotation from '../Quotation/Quotation';
import ScrollText from '../Secteur/Description/ScrollText/ScrollText';
import Etudes from '../Secteur/Etudes/Etudes';
import TextBlock from '../TextBlock/TextBlock';
import Expertises from './Expertises/Expertises';
import Menu from './Menu/Menu';
import styles from './Persona.module.scss';

function Persona({ url }: WithAnimatedRouteProps) {
	const { t, ready } = useTranslation(['persona', 'secteurs']);

	const isMobile = useMediaQuery({ query: media['<tablet'] });
	const isTablet = useMediaQuery({ query: media['<laptop'] });

	const persona =
		listPersonas.find(({ routes }) => [routes.fr, routes.en].includes(url)) ||
		null;

	const oldPersona = useRef<typeof persona>();

	useEffect(() => {
		if (oldPersona.current) window.scrollTo(0, 0);
		oldPersona.current = persona;
	}, [persona]);

	return (
		persona &&
		(ready ? (
			<>
				<article className={styles.wrapper}>
					<Head
						title={t(`persona:${persona.profil}.title`)}
						description={t(`persona:${persona.profil}.description`)}
						img="/img/opengraph/persona.png"
					/>

					<SwitchTransition>
						<CSSTransition
							key={persona.profil}
							unmountOnExit
							timeout={{ exit: 800 }}
						>
							<BigTitle
								tag="h1"
								startsPage
								size="medium"
								className={styles.titleProfil}
							>
								{t(
									`persona:${persona.profil}.title${isMobile ? 'Mobile' : ''}`,
								)}
							</BigTitle>
						</CSSTransition>
					</SwitchTransition>

					<SwitchTransition>
						<CSSTransition
							key={persona.profil}
							unmountOnExit
							timeout={{ exit: 800 }}
						>
							<TextBlock
								className={styles.blockProfil1}
								title={t(`persona:${persona.profil}.block1.title`)}
								content={
									<Trans i18nKey={`persona:${persona.profil}.block1.content`} />
								}
							/>
						</CSSTransition>
					</SwitchTransition>

					<div className={styles.filler} />

					{!isMobile && <Menu className={styles.menu} />}
					<BigTitle tag="h2" size="small" className={styles.titleSecteur}>
						{t(`secteurs:${persona.secteur}.title`)}
					</BigTitle>
					<Illustration
						parallax
						{...illustrations.secteurs[persona.secteur]}
						alt={t(`secteurs:${persona.secteur}.title`)}
						className={styles.imgSecteur}
						background={{
							...illustrations.secteurs[persona.secteur].background,
							sizes: {
								default: '36vw',
								'<desktop': '43vw',
								'<laptop': '39vw',
								'<tablet': '77vw',
							},
						}}
					/>
					<TextBlock
						level="h3"
						className={styles.blockSecteur}
						title={t(`secteurs:${persona.secteur}.block1.title`)}
						content={
							<Trans i18nKey={`secteurs:${persona.secteur}.block1.content`} />
						}
						link={{
							children: t('persona:ctaSecteurs'),
							to: routes.secteurs[persona.secteur],
						}}
					/>
					<ScrollText className={styles.scrollText} />
					<TextBlock
						className={styles.blockProfil2}
						title={t(`persona:${persona.profil}.block2.title`)}
						content={
							<Trans i18nKey={`persona:${persona.profil}.block2.content`} />
						}
					/>
					<Quotation
						className={styles.quotation}
						author={t(`persona:${persona.profil}.quotation.author`)}
						content={t(`persona:${persona.profil}.quotation.content`)}
					/>
					<Expertises profil={persona.profil} className={styles.expertises} />
				</article>

				<div className={styles.etudes}>
					<Etudes secteur={persona.secteur} />
				</div>

				{isTablet && (
					<div className={styles.menuBottomWrapper}>
						<Menu className={styles.menuBottom} />
					</div>
				)}
			</>
		) : (
			<LoadingPage />
		))
	);
}

export default Persona;
