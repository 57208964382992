import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import styles from './Menu.module.scss';

interface MenuProps {
	active: number;
	className: string;
	scrollToElement: HTMLDivElement | null;
}

function Menu({ active, className, scrollToElement }: MenuProps) {
	const { t } = useTranslation('expertises');

	const { pathname } = useLocation();

	const expertises: { title: string }[] = t('expertises', {
		returnObjects: true,
		defaultValue: [],
	});

	return (
		<ul className={[styles.wrapper, className].join(' ')}>
			{expertises.map(({ title }, i) => (
				<li key={i}>
					<Link
						to={`${pathname}?e=${i}`}
						onClick={() => scrollToElement?.scrollIntoView()}
						className={[styles.item, active === i && styles.active]
							.filter(Boolean)
							.join(' ')}
					>
						<span>{('0' + (i + 1)).slice(-2)}</span> {title.replace('.', '')}
					</Link>
				</li>
			))}
		</ul>
	);
}

export default Menu;
