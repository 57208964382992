import React from 'react';
import styles from './LoadingPage.module.scss';

function LoadingPage() {
	return (
		<div className={styles.wrapper}>
			<div className={styles.loader} />
		</div>
	);
}

export default LoadingPage;
