import { ComponentProps } from 'react';
import { SecteurTypes } from '../../../routes/secteurs';
import Clients from '../../Clients/Clients';

export const data: {
	[key in SecteurTypes]: {
		projets: number;
		typeProjet: 'projets' | 'ppp' | 'immeubles' | 'start-ups';
		collaborateurs: number;
		clients: ComponentProps<typeof Clients>['clients'];
	};
} = {
	'energies-utilities': {
		projets: 100,
		typeProjet: 'projets',
		collaborateurs: 10,
		clients: [
			'Voltalia',
			'DIF',
			'Neoen',
			'Commission de régulation de l’énergie',
			'Gedia',
			'Vinci',
			'Urbasolar',
			'Sade',
			'Ostwind',
			'Total Eren',
			'Demeter',
		],
	},
	'infrastructures-sociales': {
		projets: 60,
		typeProjet: 'ppp',
		collaborateurs: 15,
		clients: [
			'Bouygues Construction',
			'Bam PPP',
			'Eiffage',
			'Vinci',
			'Mirova',
			'Meridiam',
			'Spie Batignolles',
			'GCC',
			'3i',
			'Groupe Duval',
			'Demathieu Bard',
			'Fayat',
			'Vivalto Home',
		],
	},
	'mobilite-smart-city': {
		projets: 20,
		typeProjet: 'start-ups',
		collaborateurs: 0,
		clients: [
			'Spallian',
			'Qucit',
			'Caisse des Dépôts Groupe',
			'Demeter',
			'Kouros Investment',
			'Qwant',
			'EDF Pulse',
		],
	},
	'routes-transports': {
		projets: 80,
		typeProjet: 'projets',
		collaborateurs: 0,
		clients: [
			'RATPDev',
			'VINCI Airports',
			'Alstom',
			'Colas',
			'Eiffage',
			'Bouygues',
			'VIIA',
			'LISEA',
			'VINCI Concessions',
			'Keolis',
			'Rhonexpress',
			'A150 ALBEA',
			'Atlandes',
			'Alicorne',
		],
	},
	immobilier: {
		projets: 350,
		typeProjet: 'immeubles',
		collaborateurs: 30,
		clients: [
			'Amundi',
			'Urban Premium',
			'GE Real Estate',
			'Ceetrus',
			'Ivanhoé Cambridge',
			'Malakoff Médéric Humanis',
			'CDC Habitat',
			'Icade',
			'Atream',
		],
	},
	telecoms: {
		projets: 80,
		typeProjet: 'projets',
		collaborateurs: 0,
		clients: [
			'Axione',
			'Altitude Infrastructure',
			'Orange',
			'TDF',
			'Caisse des Dépôts Groupe',
			'Infravia',
			'DIF',
			'Marguerite',
			'Rosace Fibre',
			'Losange Fibre',
			'NGE',
			'Quaero Capital',
			'Iliad',
		],
	},
};
