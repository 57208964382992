import { RefObject, useCallback, useEffect, useRef } from 'react';
import { passiveSupported } from '../utils/passiveSupported';

interface Props {
	preventScroll: boolean;
	area: RefObject<HTMLElement>;
	goTo: (direction: 1 | -1) => void;
}

export function useWheelTouchAndKeyboard({ area, goTo, preventScroll }: Props) {
	const touchStart = useRef(0);
	const passive = useRef(!preventScroll);

	const controlledGoTo = useCallback(
		(direction: 1 | -1) => {
			const {
				scrollTop,
				scrollHeight,
				clientHeight,
			} = document.documentElement;
			const totallyScrolled = scrollHeight - scrollTop === clientHeight;

			if (direction === 1 && totallyScrolled) goTo(1);
			if (direction === -1 && scrollTop === 0) goTo(-1);
		},
		[goTo],
	);

	const handleWheel = useCallback(
		(e: WheelEvent) => {
			controlledGoTo(e.deltaY > 0 ? 1 : -1);
		},
		[controlledGoTo],
	);

	const handleKeyboard = useCallback(
		(e: KeyboardEvent) => {
			if (e.key === 'ArrowDown') controlledGoTo(1);
			else if (e.key === 'ArrowUp') controlledGoTo(-1);
		},
		[controlledGoTo],
	);

	const handleTouchStart = useCallback((e: TouchEvent) => {
		const { clientY } = e.touches[0];
		touchStart.current = clientY;
	}, []);

	const handleTouchMove = useCallback(
		(e: TouchEvent) => {
			if (!passive.current && e.cancelable) e.preventDefault();
			const { clientY } = e.touches[0];
			const diff = touchStart.current - clientY;
			controlledGoTo(diff > 0 ? 1 : -1);
		},
		[controlledGoTo],
	);

	useEffect(() => {
		if (!area.current) return;

		const el = area.current;

		const options = passiveSupported ? { passive: passive.current } : false;

		el.addEventListener('wheel', handleWheel, options);
		el.addEventListener('keydown', handleKeyboard, options);
		el.addEventListener('touchstart', handleTouchStart, options);
		el.addEventListener('touchmove', handleTouchMove, options);

		return () => {
			el.removeEventListener('wheel', handleWheel);
			el.removeEventListener('keydown', handleKeyboard);
			el.removeEventListener('touchstart', handleTouchStart);
			el.removeEventListener('touchmove', handleTouchMove);
		};
	}, [area, handleWheel, handleKeyboard, handleTouchStart, handleTouchMove]);
}
