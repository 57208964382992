import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReadyIllustration } from '../../../Illustration/illustrations';
import Link from '../../../Link/Link';
import { pages } from '../variables';
import styles from './Pages.module.scss';

interface PagesProps {
	className: string;
	setImg: (img: ReadyIllustration) => void;
}

function Pages({ className, setImg }: PagesProps) {
	const { t } = useTranslation('common');

	return (
		<ul className={[styles.wrapper, className].join(' ')}>
			{pages.map((page, i) => {
				return (
					<li
						key={i}
						className={styles.item}
						onMouseEnter={() => !page.subPages && setImg(page.img)}
					>
						{page.subPages ? (
							<>
								<span className={styles.link + ' ' + styles.notClickable}>
									{t(page.name)}
								</span>

								<ul className={styles.subMenu}>
									{page.subPages.map((subPage, o) => (
										<li key={o} onMouseEnter={() => setImg(subPage.img)}>
											<Link
												to={subPage.path}
												hash={subPage.hash}
												className={styles.subLink}
												activeClassName={styles.active}
											>
												{t(subPage.name)}
											</Link>
										</li>
									))}
								</ul>
							</>
						) : (
							<Link
								to={page.path!}
								hash={page.hash}
								className={styles.link}
								activeClassName={styles.active}
							>
								{t(page.name)}
							</Link>
						)}
					</li>
				);
			})}
		</ul>
	);
}

export default Pages;
