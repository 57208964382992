import React, { memo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import Quotation from '../../../components/Quotation/Quotation';
import TextBlock from '../../../components/TextBlock/TextBlock';
import routes from '../../../routes';
import { SecteurTypes } from '../../../routes/secteurs';
import BigTitle from '../../BigTitle/BigTitle';
import Illustration from '../../Illustration/Illustration';
import { illustrations } from '../../Illustration/illustrations';
import styles from './Description.module.scss';
import Menu from './Menu/Menu';
import ScrollText from './ScrollText/ScrollText';

type DescriptionProps = { secteur: SecteurTypes };

function Description({ secteur }: DescriptionProps) {
	const { t } = useTranslation('secteurs');

	return (
		<article className={styles.wrapper}>
			<SwitchTransition>
				<CSSTransition key={secteur} unmountOnExit timeout={{ exit: 800 }}>
					<BigTitle tag="h1" size="medium" className={styles.title} startsPage>
						{t(`${secteur}.title`)}
					</BigTitle>
				</CSSTransition>
			</SwitchTransition>

			<SwitchTransition>
				<CSSTransition
					appear
					key={secteur}
					classNames={styles}
					timeout={{ exit: 600 }}
				>
					<Illustration
						{...illustrations.secteurs[secteur]}
						alt={t(`${secteur}.block1.title`)}
						className={styles.img}
					/>
				</CSSTransition>
			</SwitchTransition>

			<SwitchTransition>
				<CSSTransition key={secteur} unmountOnExit timeout={{ exit: 800 }}>
					<TextBlock
						className={styles.block1}
						title={t(`${secteur}.block1.title`)}
						content={<Trans i18nKey={`secteurs:${secteur}.block1.content`} />}
					/>
				</CSSTransition>
			</SwitchTransition>

			<Menu className={styles.menu} />

			<ScrollText className={styles.scrollText} />

			<TextBlock
				className={styles.block2}
				title={t(`${secteur}.block2.title`)}
				content={<Trans i18nKey={`secteurs:${secteur}.block2.content`} />}
				link={{ to: routes.expertises, children: t('cta') }}
			/>

			<Quotation
				small
				className={styles.quotation}
				content={t(`${secteur}.quotation.content`)}
				author={t(`${secteur}.quotation.author`)}
			/>
		</article>
	);
}

export default memo(Description);
