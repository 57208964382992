import React from 'react';
import { useTranslation } from 'react-i18next';
import { TransitionStatus } from 'react-transition-group/Transition';
import Liste from '../Liste/Liste';
import { Result } from '../ModuleRecherche';
import styles from './Results.module.scss';

interface ResultsProps {
	error: string;
	hidden: boolean;
	loading: boolean;
	results: Result[];
	state: TransitionStatus;
	onLinkClick: () => void;
}

function Results({
	state,
	error,
	hidden,
	loading,
	results,
	onLinkClick,
}: ResultsProps) {
	const { t } = useTranslation('common');

	return (
		<div
			className={[styles.wrapper, styles[state], hidden && styles.hidden]
				.filter(Boolean)
				.join(' ')}
		>
			<p className={styles.header}>
				{error ? (
					<span className={styles.error}>{error}</span>
				) : loading ? (
					t('recherche.chargement')
				) : (
					<>
						<strong>{results.length}</strong>{' '}
						{t('recherche.résultat') + (results.length === 1 ? '' : 's')}
					</>
				)}
			</p>

			{!loading && (
				<div className={styles.lists}>
					{['offre', 'actualite', 'etude', 'page'].map(type => (
						<Liste
							key={type}
							onLinkClick={onLinkClick}
							title={t(`recherche.${type}s`)}
							list={results.filter(r => r.type === type)}
						/>
					))}
				</div>
			)}
		</div>
	);
}

export default Results;
