import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './Valeurs.module.scss';

interface ValeursProps {
	className: string;
}

function Valeurs({ className }: ValeursProps) {
	const { t } = useTranslation('carrieres');

	const valeurs = t('valeurs.list', {
		returnObjects: true,
		defaultValue: [],
	}) as { title: string; content: string }[];

	const [index, setIndex] = useState(0);

	return (
		<section className={[styles.wrapper, className].join(' ')}>
			<h2 className={styles.title}>{t('valeurs.title')}</h2>

			<ul className={styles.list}>
				{valeurs.map((valeur, i) => (
					<Section
						key={i}
						{...valeur}
						active={i === index}
						onClick={() => setIndex(i)}
					/>
				))}
			</ul>
		</section>
	);
}

interface SectionProps {
	title: string;
	content: string;
	active: boolean;
	onClick: () => void;
}

function Section({ title, content, active, onClick }: SectionProps) {
	const h3 = useRef<HTMLHeadingElement>(null);
	const p = useRef<HTMLParagraphElement>(null);

	const [h3Height, setH3Height] = useState(0);
	const [pHeight, setPHeight] = useState(0);

	useEffect(() => {
		setH3Height(h3.current!.getBoundingClientRect().height);
		setPHeight(p.current!.getBoundingClientRect().height);
	}, []);

	return (
		<li
			style={{ height: active ? h3Height + pHeight : h3Height }}
			className={[styles.item, active && styles.active]
				.filter(Boolean)
				.join(' ')}
		>
			<h3 ref={h3} className={styles.subtitle} onClick={onClick}>
				{title}
			</h3>

			<p ref={p}>{content}</p>
		</li>
	);
}

export default Valeurs;
