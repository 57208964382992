import React, { ComponentProps } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';
import BigText from '../../BigText/BigText';
import BigTitle from '../../BigTitle/BigTitle';
import Clients from '../../Clients/Clients';
import Quotation from '../../Quotation/Quotation';
import TextBlock from '../../TextBlock/TextBlock';
import styles from './References.module.scss';

const clients: ComponentProps<typeof Clients>['clients'] = [
	'Bouygues',
	'Eiffage',
	'Vinci',
	'Engie',
	'EDF',
	'Neoen',
	'RATP',
	'Banque des territoires caisse des dépots',
	'Meridiam',
	'BPI France',
	'Amundi',
	'Icamap',
	'Ivanhoé Cambridge',
	'Schroders',
	'DIF',
];

function References() {
	const { t } = useTranslation('vision');
	const [ref, inView] = useInView({
		rootMargin: '-200px 0px 0px',
		triggerOnce: true,
	});

	return (
		<article
			id="references"
			className={[styles.wrapper, !inView && styles.bgHidden]
				.filter(Boolean)
				.join(' ')}
		>
			<BigTitle tag="h1" size="big" className={styles.title}>
				{t('vision:references.title')}
			</BigTitle>

			<Quotation
				className={styles.quotation}
				content={t('vision:references.quotation.content')}
				author={t('vision:references.quotation.author')}
			/>

			<TextBlock
				className={styles.block1}
				title={t('references.block1.title')}
				content={<Trans i18nKey="vision:references.block1.content" />}
			/>

			<BigText className={styles.count}>
				{[{ word: '500' }, { word: t('references.projets'), stroke: true }]}
			</BigText>

			{/* Sert pour le reveal du background en même temps que Clients */}
			<div ref={ref} className={styles.clientsList} />

			<Clients clients={clients} className={styles.clientsList} />

			{['tl', 'tr', 'bl', 'br'].map((pos, i) => (
				<div key={i} className={[styles.bg, styles[pos]].join(' ')} />
			))}
		</article>
	);
}

export default References;
