import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'intersection-observer'; // polyfill
import React from 'react';
import ReactDOM from 'react-dom';
import 'destyle.css';
import './styles/style.scss';
import App from './App/App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import './i18n';
import { ParallaxProvider } from 'react-scroll-parallax';
import ReactGA from "react-ga";
import { isProd } from './utils/env';

if ('scrollRestoration' in window.history) {
	window.history.scrollRestoration = 'manual';
}

if (isProd) {
	ReactGA.initialize('UA-87502719-1');
	ReactGA.set({ anonymizeIp: true });
}

ReactDOM.render(
	<ParallaxProvider>
		<BrowserRouter>
			<App />
		</BrowserRouter>
	</ParallaxProvider>,
	document.getElementById('root'),
);

serviceWorker.register();
