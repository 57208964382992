import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import routes from '../../../routes';
import { media } from '../../../utils/breakpoints/breakpoints';
import BigTitle from '../../BigTitle/BigTitle';
import Illustration from '../../Illustration/Illustration';
import { illustrations } from '../../Illustration/illustrations';
import Quotation from '../../Quotation/Quotation';
import TextBlock from '../../TextBlock/TextBlock';
import styles from './International.module.scss';

function International() {
	const { t } = useTranslation('vision');

	const isSmallDevice = useMediaQuery({ query: media['<tablet'] });

	return (
		<article id="international" className={styles.wrapper}>
			<BigTitle tag="h1" size="big" className={styles.title}>
				{t(`international.${isSmallDevice ? 'titleMobile' : 'title'}`)}
			</BigTitle>

			<Illustration
				parallax
				className={styles.img}
				{...illustrations.international}
				alt={t('international.block1.title')}
			/>

			<TextBlock
				className={styles.block1}
				title={t('international.block1.title')}
				content={<Trans i18nKey="vision:international.block1.content" />}
				link={{ to: routes.carte, children: t('international.block1.cta') }}
			/>

			<Quotation
				className={styles.quotation}
				content={t('international.quotation.content')}
				author={t('international.quotation.author')}
			/>

			<TextBlock
				className={styles.block2}
				title={t('international.block2.title')}
				content={<Trans i18nKey="vision:international.block2.content" />}
			/>
		</article>
	);
}

export default International;
