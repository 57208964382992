import React from 'react';
import styles from './Arbre.module.scss';

interface ArbreType {
	arbre: { width: number };
	ombre: { x: number; width: number };
}

const arbres: ArbreType[] = [
	{ arbre: { width: 3 }, ombre: { x: 3.2, width: 4.5 } },
	{ arbre: { width: 2 }, ombre: { x: 3.1, width: 3.5 } },
	{ arbre: { width: 3.5 }, ombre: { x: 1, width: 4.5 } },
	{ arbre: { width: 1 }, ombre: { x: 1, width: 1.7 } },
	{ arbre: { width: 1.2 }, ombre: { x: 1.2, width: 1.5 } },
	{ arbre: { width: 1.7 }, ombre: { x: 1.3, width: 2.2 } },
	{ arbre: { width: 1.1 }, ombre: { x: 1.1, width: 1.5 } },
	{ arbre: { width: 2.2 }, ombre: { x: 1.7, width: 1.3 } },
	{ arbre: { width: 2.2 }, ombre: { x: 1, width: 1.3 } },
];

interface ArbreProps {
	x: number;
	y: number;
	type: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;
}

function Arbre({ type, x, y }: ArbreProps) {
	const transitionDelay = Math.random() * 3 + 's';

	return (
		<>
			<img
				alt=""
				className={styles.ombre}
				src={`/img/home/arbres/ombre${type}.png`}
				style={{
					top: y + '%',
					left: x + arbres[type - 1].ombre.x + '%',
					width: arbres[type - 1].ombre.width + '%',
					transitionDelay,
				}}
			/>

			<div
				className={styles.arbreWrapper}
				style={{
					top: y + '%',
					left: x + '%',
					width: arbres[type - 1].arbre.width + '%',
				}}
			>
				<img
					alt=""
					className={styles.arbre}
					src={`/img/home/arbres/arbre${type}.png`}
					style={{ transitionDelay }}
				/>
			</div>
		</>
	);
}

export default Arbre;
