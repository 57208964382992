import React from 'react';
import Popin from '../../Popin/Popin';
import { Expertise } from '../Expertises';
import styles from './PopinSolutions.module.scss';

interface PopinSolutionsProps {
	isOpen: boolean;
	close: () => void;
	expertise: Expertise;
}

function PopinSolutions({ expertise, close, isOpen }: PopinSolutionsProps) {
	return (
		<Popin isOpen={isOpen} onCacheClick={close} className={styles.wrapper}>
			<p className={styles.title}>{expertise.title}</p>
			<ul className={styles.list}>
				{expertise.solutions.map((item, i) => (
					<li key={i}>{item}</li>
				))}
			</ul>
		</Popin>
	);
}

export default PopinSolutions;
