import routes, { TranslatedRoute } from '../../../routes';
import { SecteurTypes } from '../../../routes/secteurs';
import {
	illustrations,
	ReadyIllustration,
} from '../../Illustration/illustrations';

export type Page = {
	name: string;
} & (
	| {
			hash?: string;
			path: TranslatedRoute;
			img: ReadyIllustration;
			subPages?: undefined;
	  }
	| {
			path?: undefined;
			img?: undefined;
			subPages: {
				name: string;
				path: TranslatedRoute;
				img: ReadyIllustration;
				hash?: string;
			}[];
	  }
);

export const pages: Page[] = [
	{
		name: 'menu.vision',
		subPages: [
			{
				name: 'vision.histoire',
				img: illustrations.vision,
				path: routes.vision,
				hash: '#histoire',
			},
			{
				name: 'vision.expertises',
				img: illustrations.vision,
				path: routes.vision,
				hash: '#expertises',
			},
			{
				name: 'vision.international',
				img: illustrations.vision,
				path: routes.vision,
				hash: '#international',
			},
			{
				name: 'vision.collaboratif',
				img: illustrations.vision,
				path: routes.vision,
				hash: '#collaboratif',
			},
			{
				name: 'vision.references',
				img: illustrations.vision,
				path: routes.vision,
				hash: '#references',
			},
			{
				name: 'vision.engagement',
				img: illustrations.vision,
				path: routes.vision,
				hash: '#engagement',
			},
		],
	},
	{
		name: 'menu.secteurs',
		subPages: Object.entries(routes.secteurs)
			.map(([key]) => key as SecteurTypes)
			.map((s) => ({
				name: `secteurs.${s}`,
				path: routes.secteurs[s],
				img: illustrations.secteurs[s],
			})),
	},
	{
		name: 'menu.expertises',
		path: routes.expertises,
		img: illustrations.expertises,
	},
	{
		name: 'menu.carrieres',
		path: routes.carrières,
		img: illustrations.carrieres,
	},
	{
		name: 'menu.actualites',
		path: routes.actualités,
		img: illustrations.actualites,
	},
];
