import { TranslatedRoute } from '.';
import { secteursTrads, SecteurTypes } from './secteurs';

const profils = [
	['developpeur', 'project-developer'],
	['investisseur', 'investor'],
	['gestionnaire-actifs', 'asset-manager'],
	['preteur', 'lender'],
	['exploitant', 'operator'],
	['personne-publique', 'public-entity'],
] as const;

export type ProfilTypes = typeof profils[number][0];

export const listPersonas = profils
	.map(([profilFR, profilEN]) => {
		return secteursTrads.map(([secteurFR, secteurEN]) => {
			return {
				profil: profilFR,
				secteur: secteurFR,
				routes: {
					fr: `/fr/persona/${profilFR}/${secteurFR}`,
					en: `/en/persona/${profilEN}/${secteurEN}`,
				},
			} as {
				secteur: SecteurTypes;
				profil: ProfilTypes;
				routes: { fr: string; en: string };
			};
		});
	})
	.flat();

export default listPersonas.reduce((acc, curr) => {
	acc[curr.profil] = acc[curr.profil] || {};
	acc[curr.profil][curr.secteur] = curr.routes;
	return acc;
}, Object.create(null)) as Record<
	ProfilTypes,
	Record<SecteurTypes, TranslatedRoute>
>;
