import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import slugify from 'slugify';
import { media } from '../../../../utils/breakpoints/breakpoints';
import { team } from '../../../../utils/team';
import Img from '../../../Img/Img';
import { ReactComponent as LinkedInSVG } from '../../../Share/linkedin.svg';
import styles from './Partners.module.scss';

interface PartnersProps {
	showMore: boolean;
	className: string;
}

function Partners({ showMore, className }: PartnersProps) {
	const { t } = useTranslation('common');

	const isMobile = useMediaQuery({ query: media['<tablet'] });

	const array = isMobile && !showMore ? team.slice(0, 3) : team;

	return (
		<ul className={[styles.wrapper, className].join(' ')}>
			{array.map(({ linkedIn, name, post }, i) => (
				<li
					key={i}
					className={[styles.item, linkedIn && styles.hasLinkedIn]
						.filter(Boolean)
						.join(' ')}
				>
					<Img
						width={164}
						height={300}
						alt={name}
						src={
							'/img/vision/collaboratif/equipe/' +
							slugify(name, { lower: true })
						}
						widths={[164, 328]}
						className={styles.photo}
						imgClassName={styles.img}
						sizes={{ default: '164px' }}
					/>

					<h3 className={styles.name}>{name}</h3>

					<p className={styles.post}>{t(`contact.${post}`)}</p>

					{linkedIn && (
						<a
							target="_blank"
							rel="noopener noreferrer"
							className={styles.linkedIn}
							href={'https://fr.linkedin.com/in/' + linkedIn}
						>
							<LinkedInSVG />
						</a>
					)}
				</li>
			))}

			{[...new Array(3)].map((_, i) => (
				<li key={i} className={styles.item + ' ' + styles.fake} />
			))}
		</ul>
	);
}

export default Partners;
