import React, { forwardRef, ReactNode, Ref } from 'react';
import { createPortal } from 'react-dom';
import { CSSTransition } from 'react-transition-group';
import XButton from '../XButton/XButton';
import styles from './Popin.module.scss';

interface PopinProps {
	isOpen: boolean;
	className?: string;
	children: ReactNode;
	onCacheClick: () => void;
}

const Popin = forwardRef(
	(
		{ children, isOpen, className, onCacheClick }: PopinProps,
		ref?: Ref<HTMLDivElement>,
	) =>
		createPortal(
			<CSSTransition
				in={isOpen}
				mountOnEnter
				unmountOnExit
				classNames={styles}
				timeout={+styles.duration}
			>
				<div ref={ref} className={styles.wrapper}>
					<div className={styles.cache} onClick={onCacheClick} />
					<div
						className={[styles.content, className].filter(Boolean).join(' ')}
					>
						{children}
						<XButton onClick={onCacheClick} className={styles.close} />
					</div>
				</div>
			</CSSTransition>,
			document.getElementById('popin')!,
		),
);

export default Popin;
