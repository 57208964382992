import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { useScrolled } from '../../hooks/useScrolled';
import { Lang } from '../../types';
import { getWordPressPosts } from '../../utils/wordpress';
import {
	WordPressActualite,
	WordPressEtude,
	WordPressPostType,
} from '../../utils/wordpress/types';
import BigTitle from '../BigTitle/BigTitle';
import { Option } from '../Filtre/Filtre';
import FiltreRadio from '../FiltreRadio/FiltreRadio';
import Head from '../Head/Head';
import LoadingPage from '../LoadingPage/LoadingPage';
import VignetteActualite from '../VignetteActualite/VignetteActualite';
import VignetteLoading from '../VignetteLoading/VignetteLoading';
import styles from './Actualites.module.scss';
import Pagination from './Pagination/Pagination';

const limit = 12;

type Post = WordPressActualite | WordPressEtude;

function Actualites() {
	const [posts, setPosts] = useState<Post[]>([]);
	const [loading, setLoading] = useState(true);
	const [total, setTotal] = useState(0);

	const { t, ready, i18n } = useTranslation('actualites');

	const { search, pathname } = useLocation();
	const history = useHistory();
	const params = new URLSearchParams(search);
	const type = (params.get('type') as WordPressPostType) || undefined;
	const page = params.get('page') || 1;

	const { scrolled } = useScrolled();

	const fetchPosts = useCallback(
		async function () {
			const { posts, total } = await getWordPressPosts<Post>({
				limit,
				page: +page,
				lang: i18n.language as Lang,
				type: type ? type : 'actualite',
			});
			return { actus: posts, total };
		},
		[i18n.language, type, page],
	);

	useEffect(() => {
		let isMounted = true;
		setLoading(true);
		fetchPosts()
			.then(({ actus, total }) => {
				if (!isMounted) return;
				setPosts(actus);
				setTotal(total);
			})
			.catch((err) => isMounted && console.error(err.message))
			.finally(() => isMounted && setLoading(false));

		return () => {
			isMounted = false;
		};
	}, [fetchPosts]);

	const oldPage = useRef<typeof page>();

	useEffect(() => {
		if (oldPage.current) window.scrollTo(0, 0);
		oldPage.current = page;
	}, [page]);

	const filtres: Option[] = ready
		? [
				{ label: t('filtres.actualites'), value: 'actualite' },
				{ label: t('filtres.etudes'), value: 'etude' },
		  ]
		: [];

	return ready ? (
		<section
			className={[styles.wrapper, scrolled && styles.scrolled]
				.filter(Boolean)
				.join(' ')}
		>
			<Head
				title={t('title')}
				description={t('description')}
				img="/img/opengraph/actualites.png"
			/>

			<BigTitle size="medium" tag="h1" className={styles.title} startsPage>
				{t('title')}
			</BigTitle>

			<FiltreRadio
				options={filtres}
				className={styles.filtre}
				current={filtres.find((f) => f.value === type) || filtres[0]}
				setOption={(opt) => {
					if (opt.value === type) return;
					history.push(`${pathname}?type=${opt.value}`);
				}}
			/>

			<Pagination
				total={total}
				perPage={limit}
				className={styles.pagination1}
			/>

			<ul className={styles.list}>
				{loading
					? [...new Array(limit)].map((_, i) => (
							<li key={i} className={styles.item}>
								<VignetteLoading />
							</li>
					  ))
					: posts.map((post) => (
							<li key={post.id} className={styles.item}>
								<VignetteActualite
									type={post.type}
									date={post.date}
									slug={post.slug}
									photo={post.acf.image}
									title={post.title.rendered}
									content={
										(post as WordPressEtude).acf.introduction ||
										(post as WordPressActualite).content.rendered ||
										''
									}
								/>
							</li>
					  ))}

				{[...new Array(limit)].map((_, i) => (
					<li key={i} className={styles.item + ' ' + styles.fake} />
				))}
			</ul>

			<Pagination
				total={total}
				perPage={limit}
				className={styles.pagination2}
			/>
		</section>
	) : (
		<LoadingPage />
	);
}

export default Actualites;
