import React from 'react';
import { useTranslation } from 'react-i18next';
import { team } from '../../utils/team';
import Img from '../Img/Img';
import styles from './Contact.module.scss';

interface ContactProps {
	nom: string;
	className?: string;
	type: 'short' | 'full';
}

function Contact({ nom, type, className }: ContactProps) {
	const { t } = useTranslation('common');

	const contact = team.find(person => person.slug === nom);

	const poste = contact && t(`contact.${contact?.post}`);

	const email = (
		<a
			target="_blank"
			rel="noopener noreferrer"
			className={styles.mailto}
			href={`mailto:${contact?.email}`}
		>
			{contact?.email}
		</a>
	);

	return contact ? (
		<div
			className={[styles.wrapper, styles[type], className]
				.filter(Boolean)
				.join(' ')}
		>
			{type === 'short' ? (
				<>
					<p className={styles.title}>{t('contact.title')}</p>

					<p className={styles.text}>{t('contact.forMoreInfo')}</p>

					<p>
						<strong>{contact.name},</strong> {poste}
					</p>

					<p className={styles.big}>
						{contact.telephone} / {email}
					</p>
				</>
			) : (
				<>
					<Img
						width={100}
						height={100}
						alt={contact.name}
						widths={[100, 200, 300]}
						className={styles.photo}
						imgClassName={styles.img}
						sizes={{ default: '100px' }}
						src={'/img/contact/' + contact.slug}
					/>

					<p>{contact.name}</p>

					<p>
						<strong>{poste}</strong>
					</p>

					<p className={styles.big}>{contact.telephone}</p>

					<p>{email}</p>
				</>
			)}
		</div>
	) : null;
}

export default Contact;
