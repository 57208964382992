import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import routes, { allRoutes } from '../../../routes';
import { Lang } from '../../../types';
import { WordPressPostType } from '../../../utils/wordpress/types';
import styles from './SwitchLang.module.scss';

const newsRoutes = Object.values(routes.actualité).map((p) =>
	p.replace(':slug', ''),
);

const studiesRoutes = Object.values(routes.étude).map((p) =>
	p.replace(':slug', ''),
);

interface SwitchLangProps {
	className: string;
}

function SwitchLang({ className }: SwitchLangProps) {
	const { pathname } = useLocation();
	const { i18n } = useTranslation();

	const lang = pathname.slice(1, 3) as Lang;

	useEffect(() => {
		i18n.changeLanguage(lang);
	}, [lang, i18n]);

	return (
		<p className={[styles.wrapper, className].join(' ')}>
			<LangLink lang="fr" /> / <LangLink lang="en" />
		</p>
	);
}

function LangLink({ lang }: { lang: Lang }) {
	const { pathname, search } = useLocation();

	const route = allRoutes.find((tr) => [tr.en, tr.fr].includes(pathname));

	const isActive = lang === (pathname.slice(1, 3) as Lang);
	const isNews = newsRoutes.find((p) => pathname.includes(p));
	const isStudy = studiesRoutes.find((p) => pathname.includes(p));

	const params = new URLSearchParams(search);
	const type = (params.get('type') as WordPressPostType) || undefined;

	let to = pathname;
	if (!isActive) {
		if (route?.[lang]) {
			to = route?.[lang] + (type ? '?type=' + type : '');
		} else if (isNews) {
			to =
				routes.actualités[lang] + '?type=' + ('actualite' as WordPressPostType);
		} else if (isStudy) {
			to = routes.actualités[lang] + '?type=' + ('etude' as WordPressPostType);
		} else {
			to = routes.splashScreen[lang];
		}
	}

	return (
		<Link
			to={to}
			hrefLang={lang}
			className={[styles.link, isActive && styles.active]
				.filter(Boolean)
				.join(' ')}
		>
			{lang.toUpperCase()}
		</Link>
	);
}

export default SwitchLang;
