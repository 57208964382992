import React from 'react';
import { useTranslation } from 'react-i18next';
import routes from '../../routes';
import Link from '../Link/Link';
import styles from './BtnBack.module.scss';

interface BtnBackProps {
	className: string;
	type: 'actus' | 'offres';
}

function BtnBack({ type, className }: BtnBackProps) {
	const { t, ready } = useTranslation('common');

	return (
		<Link
			className={[styles.wrapper, className].join(' ')}
			to={type === 'actus' ? routes.actualités : routes.carrières}
		>
			<span className={styles.backArrow} />
			{ready && t(type === 'actus' ? 'toutesActus' : 'toutesOffres')}
		</Link>
	);
}

export default BtnBack;
