let canHavePassive = false;

try {
	const options = Object.defineProperty({}, 'passive', {
		get: () => (canHavePassive = true),
	});

	window.addEventListener('test', options, options);
	window.removeEventListener('test', options, options);
} catch (err) {
	canHavePassive = false;
}

export const passiveSupported = canHavePassive;
