import React from 'react';
import SkeletonLine from '../SkeletonLine/SkeletonLine';
import styles from './VignetteLoading.module.scss';

function VignetteLoading() {
	return (
		<div className={styles.wrapper}>
			<div className={styles.photo} />

			<p className={styles.date}>
				<SkeletonLine size="small" />
			</p>

			<div className={styles.title}>
				<SkeletonLine size="full" />
				<SkeletonLine size="big" />
			</div>

			<p className={styles.content}>
				<SkeletonLine size="full" />
				<SkeletonLine size="full" />
				<SkeletonLine size="full" />
				<SkeletonLine size="full" />
				<SkeletonLine size="big" />
			</p>

			<div className={styles.link}>
				<SkeletonLine size="full" />
			</div>
		</div>
	);
}

export default VignetteLoading;
