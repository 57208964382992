import { decodeEntities } from '@wordpress/html-entities';
import { frenchify } from 'frenchify';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Lang } from '../../types';
import { getWordPressPostBySlug } from '../../utils/wordpress';
import { WordPressOffre } from '../../utils/wordpress/types';
import { WithAnimatedRouteProps } from '../AnimatedRoute/AnimatedRoute';
import BigTitle from '../BigTitle/BigTitle';
import BtnBack from '../BtnBack/BtnBack';
import ListeOffres from '../Carrieres/ListeOffres/ListeOffres';
import Head from '../Head/Head';
import LoadingPage from '../LoadingPage/LoadingPage';
import PageNotFound from '../PageNotFound/PageNotFound';
import TextBlock from '../TextBlock/TextBlock';
import BtnPostuler from './BtnPostuler/BtnPostuler';
import styles from './Offre.module.scss';

function sanitize(string?: string) {
	return frenchify(decodeEntities(string || ''));
}

function Offre({ params }: WithAnimatedRouteProps<{ slug: string }>) {
	const { t, i18n, ready } = useTranslation('offre');

	const [post, setPost] = useState<WordPressOffre>();
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(false);

	const oldOffre = useRef<typeof params.slug>();

	useEffect(() => {
		if (oldOffre.current) window.scrollTo(0, 0);
		oldOffre.current = params.slug;
	}, [params.slug]);

	useEffect(() => {
		if (!ready) return;
		let isMounted = true;
		setLoading(true);

		getWordPressPostBySlug<WordPressOffre>({
			type: 'offre',
			slug: params.slug,
			lang: i18n.language as Lang,
		})
			.then((res) => {
				if (!isMounted) return;
				if (res.length) setPost(res[0]);
				else setError(true);
			})
			.catch(() => isMounted && setError(true))
			.finally(() => isMounted && setLoading(false));

		return () => {
			isMounted = false;
		};
	}, [ready, params.slug, i18n.language]);

	return !error ? (
		<article className={styles.wrapper}>
			<Head
				title={sanitize(post?.title.rendered || params.slug)}
				description={sanitize(post?.acf.missions)}
				yoast={post?.head_tags}
			/>

			{loading && <LoadingPage />}

			<p className={styles.offre}>{ready && t('title')}</p>

			<BtnBack type="offres" className={styles.btnBack} />

			{post && (
				<>
					<BigTitle size="small" tag="h1" className={styles.title} startsPage>
						{sanitize(post.title.rendered)}
					</BigTitle>

					<TextBlock
						className={styles.profil}
						title={t('profil-recherche')}
						content={{
							className: styles.content,
							html: [
								`<h3>${t('experiences')}</h3>`,
								sanitize(post.acf.experiences),
								`<h3>${t('atouts')}</h3>`,
								sanitize(post.acf.atouts),
								`<h3>${t('avantages')}</h3>`,
								sanitize(post.acf.avantages),
							].join(''),
						}}
					/>

					<TextBlock
						className={styles.missions}
						title={t('missions')}
						content={{ html: sanitize(post.acf.missions) }}
					/>

					<section className={styles.detailsWrapper}>
						<div className={styles.details}>
							<h3 className={styles.subtitle}>{t('secteur-activite')}</h3>
							<p
								className={styles.content}
								dangerouslySetInnerHTML={{
									__html: sanitize(post.acf.secteur_activite),
								}}
							/>

							<h3 className={styles.subtitle}>{t('localisation')}</h3>
							<p
								className={styles.content}
								dangerouslySetInnerHTML={{
									__html: sanitize(post.acf.localisation),
								}}
							/>

							<h3 className={styles.subtitle}>{t('dispo-duree')}</h3>
							<p
								className={styles.content}
								dangerouslySetInnerHTML={{
									__html: sanitize(post.acf.disponibilite_duree),
								}}
							/>

							<h3 className={styles.subtitle}>{t('contact')}</h3>
							<p>
								<strong>{t('service-rh')}</strong> - {t('recrutement')}
								<br />
								jobs@h3p.com
							</p>

							<BtnPostuler
								title={post.title.rendered}
								className={styles.btnPostuler}
							/>
						</div>
					</section>

					<ListeOffres className={styles.listeOffres} excludeId={post.id} />
				</>
			)}
		</article>
	) : (
		<PageNotFound />
	);
}

export default Offre;
