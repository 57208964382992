import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Facebook } from '../../Share/facebook.svg';
import { ReactComponent as LinkedIn } from '../../Share/linkedin.svg';
import { ReactComponent as Twitter } from '../../Share/twitter.svg';
import styles from './Social.module.scss';

const links: { title: string; svg: FunctionComponent; url: string }[] = [
	{
		title: 'Facebook',
		svg: Facebook,
		url: 'https://www.facebook.com/h3pGroup/',
	},
	{
		title: 'Twitter',
		svg: Twitter,
		url: 'https://twitter.com/H3PGroup',
	},
	{
		title: 'LinkedIn',
		svg: LinkedIn,
		url: 'https://www.linkedin.com/company/h3p-audit-&-conseil/',
	},
];

interface SocialProps {
	className: string;
}

function Social({ className }: SocialProps) {
	const { t } = useTranslation('carrieres');

	return (
		<div className={[styles.wrapper, className].join(' ')}>
			<p className={styles.title}>{t('social')}</p>

			<ul className={styles.list}>
				{links.map(({ svg: Svg, title, url }, i) => (
					<a
						key={i}
						href={url}
						title={title}
						target="_blank"
						className={styles.item}
						rel="noopener noreferrer"
					>
						<Svg />
					</a>
				))}
			</ul>
		</div>
	);
}

export default Social;
