import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { useLocation } from 'react-router-dom';
import { useScrolled } from '../../hooks/useScrolled';
import routes, { firstSlide } from '../../routes';
import { media } from '../../utils/breakpoints/breakpoints';
import { ReactComponent as EarthSVG } from '../International/earth.svg';
import Link from '../Link/Link';
import ModuleRecherche from '../ModuleRecherche/ModuleRecherche';
import Burger from './Burger/Burger';
import styles from './Header.module.scss';
import { ReactComponent as LogoH3P } from './logo-h3p.svg';
import Menu from './Menu/Menu';
import SearchButton from './SearchButton/SearchButton';
import SwitchLang from './SwitchLang/SwitchLang';

function Header() {
	const { key } = useLocation();
	const { t, ready } = useTranslation('common');

	const { scrolled } = useScrolled();
	const [menuOpen, setMenuOpen] = useState(false);
	const [searchOpen, setSearchOpen] = useState(false);

	const { pathname } = useLocation();
	const isSplashScreen =
		pathname === '/' || Object.values(routes.splashScreen).includes(pathname);
	const isInternational = Object.values(routes.carte).includes(pathname);

	const isMobile = useMediaQuery({ query: media['<tablet'] });

	useEffect(() => {
		setMenuOpen(false);
	}, [key]);

	return (
		<header className={styles.wrapper}>
			<Link
				to={firstSlide}
				className={styles.logoH3P}
				title={ready ? t('goToHome') : ''}
			>
				<LogoH3P className={styles.svg} />
			</Link>

			<Burger
				isOpen={menuOpen}
				className={[styles.burger, isSplashScreen && styles.hidden]
					.filter(Boolean)
					.join(' ')}
				onClick={() => setMenuOpen(!menuOpen)}
			/>

			<hr className={styles.sideLine} />

			<p
				className={[
					styles.headline,
					(scrolled || menuOpen || isSplashScreen) && styles.hidden,
				]
					.filter(Boolean)
					.join(' ')}
			>
				{ready && t(isMobile ? 'headlineMobile' : 'headline')}
			</p>

			<Link
				to={routes.carte}
				className={[
					styles.ctaMap,
					(isInternational || menuOpen || isSplashScreen) && styles.hidden,
				]
					.filter(Boolean)
					.join(' ')}
			>
				<EarthSVG />
				{ready && t('projets-implantations')}
			</Link>

			<hr
				className={[
					styles.separator1,
					(isInternational || menuOpen || isSplashScreen) &&
						styles.desktopHidden,
					!menuOpen && styles.mobileHidden,
				]
					.filter(Boolean)
					.join(' ')}
			/>

			<SwitchLang
				className={[styles.switchLang, !menuOpen && styles.mobileHidden]
					.filter(Boolean)
					.join(' ')}
			/>

			<hr
				className={[styles.separator2, isSplashScreen && styles.hidden]
					.filter(Boolean)
					.join(' ')}
			/>

			<SearchButton
				onClick={() => setSearchOpen(!searchOpen)}
				className={[styles.searchButton, isSplashScreen && styles.hidden]
					.filter(Boolean)
					.join(' ')}
			/>

			{ready && <Menu isOpen={menuOpen} close={() => setMenuOpen(false)} />}

			<ModuleRecherche
				isOpen={searchOpen}
				closeRecherche={() => setSearchOpen(false)}
			/>
		</header>
	);
}

export default Header;
