import React from 'react';
import { useInView } from 'react-intersection-observer';
import styles from './BigTitle.module.scss';

interface BigTitleProps {
	children: string | string[];
	className?: string;
	size: 'small' | 'medium' | 'big';
	tag: 'h1' | 'h2' | 'h3';
	startsPage?: true;
}

function BigTitle({
	size,
	children,
	tag: Tag,
	className,
	startsPage,
}: BigTitleProps) {
	const lines = typeof children === 'string' ? [children] : children;

	return (
		<Tag
			className={[
				styles.h1,
				size && styles[size],
				startsPage && styles.startsPage,
				className,
			]
				.filter(Boolean)
				.join(' ')}
		>
			{lines.map((line, i) => (
				<Line key={i} withChevron={Tag === 'h1' && i === 0}>
					{line}
				</Line>
			))}
		</Tag>
	);
}

type LineProps = { children: string; withChevron: boolean };

function Line({ children, withChevron }: LineProps) {
	const [ref, inView] = useInView({
		triggerOnce: true,
		rootMargin: '-100px 0px 0px',
	});

	return (
		<span
			ref={ref}
			className={[styles.line, inView && styles.inView]
				.filter(Boolean)
				.join(' ')}
		>
			{withChevron && <span className={styles.chevron}></span>}

			{children.split(' ').map((word, o) => {
				const subWords = word.split('\u00ad');
				return subWords.map((w, y) => (
					<Word key={y}>{w + (y < subWords.length - 1 ? '\u2011' : '')}</Word>
				));
			})}
		</span>
	);
}

function Word({ children }: { children: string }) {
	return (
		<>
			<span className={styles.word}>
				{children.split('').map((letter, u) => (
					<span key={u} className={styles.letterCache}>
						<span
							className={styles.letter}
							style={{ transitionDelay: u * 50 + 'ms' }}
						>
							{letter}
						</span>
					</span>
				))}
			</span>{' '}
		</>
	);
}

export default BigTitle;
