import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import routes from '../../../routes';
import { SecteurTypes } from '../../../routes/secteurs';
import { Lang } from '../../../types';
import { getWordPressPosts } from '../../../utils/wordpress';
import { WordPressEtude } from '../../../utils/wordpress/types';
import BigTitle from '../../BigTitle/BigTitle';
import CallToAction from '../../CallToAction/CallToAction';
import Link from '../../Link/Link';
import VignetteActualite from '../../VignetteActualite/VignetteActualite';
import VignetteLoading from '../../VignetteLoading/VignetteLoading';
import styles from './Etudes.module.scss';

function Etudes({ secteur }: { secteur: SecteurTypes }) {
	const { t, i18n } = useTranslation('secteurs');

	const [etudes, setEtudes] = useState<WordPressEtude[]>([]);
	const [loading, setLoading] = useState(true);

	const fetchEtudes = useCallback(
		async function() {
			const { posts } = await getWordPressPosts<WordPressEtude>({
				page: 1,
				secteur,
				limit: 3,
				type: 'etude',
				lang: i18n.language as Lang,
			});
			setEtudes(posts);
		},
		[i18n.language, secteur],
	);

	useEffect(() => {
		let isMounted = true;

		setLoading(true);
		fetchEtudes()
			.catch(err => isMounted && console.error(err.message))
			.finally(() => isMounted && setLoading(false));
	}, [fetchEtudes]);

	return (
		<section className={styles.wrapper}>
			<BigTitle tag="h2" size="small" className={styles.title}>
				{t('etudes.title')}
			</BigTitle>

			<ul className={styles.list}>
				{loading
					? [...new Array(3)].map((_, i) => (
							<li key={i} className={styles['vignette' + (i + 1)]}>
								<VignetteLoading />
							</li>
					  ))
					: etudes.map((post, i) => (
							<li key={post.id} className={styles['vignette' + (i + 1)]}>
								<VignetteActualite
									type="etude"
									date={post.date}
									slug={post.slug}
									photo={post.acf.image}
									title={post.title.rendered}
									content={post.acf.introduction}
								/>
							</li>
					  ))}
				<p className={styles.toMap}>
					<span className={styles.headline}>{t('etudes.cta.headline')}</span>

					<Link to={routes.carte}>
						<CallToAction>{t('etudes.cta.button')}</CallToAction>
					</Link>
				</p>
			</ul>
		</section>
	);
}

export default Etudes;
