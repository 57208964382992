import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Popin from '../../Popin/Popin';
import styles from './Metiers.module.scss';

interface MetiersProps {
	className: string;
}

function Metiers({ className }: MetiersProps) {
	const { t } = useTranslation('carrieres');

	const metiers = t('metiers.list', {
		returnObjects: true,
		defaultValue: [],
	}) as { title: string; text1: string; text2: string }[];

	const [popinOpen, setPopinOpen] = useState(false);
	const [metier, setmetier] = useState<typeof metiers[number]>({
		title: '',
		text1: '',
		text2: '',
	});

	return (
		<div className={[styles.wrapper, className].join(' ')}>
			<h2 className={styles.title}>{t('metiers.title')}</h2>

			<ul className={styles.list}>
				{metiers.map((m, i) => (
					<li key={i}>
						<button
							onClick={() => {
								setmetier(m);
								setPopinOpen(true);
							}}
							className={[
								styles.button,
								metier.title === m.title && popinOpen && styles.active,
							]
								.filter(Boolean)
								.join(' ')}
						>
							<span>{m.title}</span>
						</button>
					</li>
				))}
			</ul>

			<Popin
				isOpen={popinOpen}
				className={styles.popin}
				onCacheClick={() => setPopinOpen(false)}
			>
				<h3>{metier.title}</h3>
				<p>{metier.text1}</p>
				<p>{metier.text2}</p>
			</Popin>
		</div>
	);
}

export default Metiers;
