import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import CallToAction from '../../CallToAction/CallToAction';
import { PersonaContext } from '../../ModulePersona/ModulePersona';
import styles from './Menu.module.scss';

interface MenuProps {
	className: string;
}

function Menu({ className }: MenuProps) {
	const { t } = useTranslation('persona');

	const { open: openModule } = useContext(PersonaContext);

	return (
		<div className={className}>
			<div className={styles.block}>
				<p>{t('modulePersona.text')}</p>

				<button onClick={openModule}>
					<CallToAction>{t('modulePersona.cta')}</CallToAction>
				</button>
			</div>
		</div>
	);
}

export default Menu;
