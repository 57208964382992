import React from 'react';
import { useTranslation } from 'react-i18next';
import routes from '../../../../routes';
import {
	siteName,
	city,
	email,
	phone,
	postalCode,
	street,
} from '../../../../utils/env';
import { ReactComponent as EarthSVG } from '../../../International/earth.svg';
import Link from '../../../Link/Link';
import styles from './Aside.module.scss';

interface AsideProps {
	className: string;
}

function Aside({ className }: AsideProps) {
	const { t } = useTranslation('common');

	return (
		<div className={[styles.wrapper, className].join(' ')}>
			<p>
				<span className={styles.projets}>{t('projets-implantations')}</span>

				<Link to={routes.carte} className={styles.linkToCarte}>
					<EarthSVG />
					{t('carte-interactive')}
				</Link>
			</p>

			<dl className={styles.contact}>
				<dt>{t('contact.title')}</dt>

				<dd>
					{siteName},
					<br />
					{street},
					<br />
					{postalCode} {city}
				</dd>

				<dd>
					<a target="_blank" rel="noopener noreferrer" href={`mailto:${email}`}>
						{email}
					</a>
				</dd>

				<dd>
					<a href={`tel:${phone.replace(/ /g, '').replace('0', '+33')}`}>
						{phone}
					</a>
				</dd>
			</dl>
		</div>
	);
}

export default Aside;
