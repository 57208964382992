import { decodeEntities } from '@wordpress/html-entities';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import routes from '../../../routes';
import { Lang } from '../../../types';
import { getExcerptFromString } from '../../../utils/excerpt';
import Arrow from '../../Arrow/Arrow';
import SkeletonLine from '../../SkeletonLine/SkeletonLine';
import styles from './VignetteOffre.module.scss';

interface VignetteOffreProps {
	type?: string;
	slug?: string;
	title?: string;
	content?: string;
	localisation?: string;
}

function VignetteOffre({
	type = '',
	slug = '',
	title = '',
	content = '',
	localisation = '',
}: VignetteOffreProps) {
	const { i18n } = useTranslation('common');

	const pathname = routes.offres[i18n.language as Lang].replace(':slug', slug);

	return (
		<article className={styles.wrapper}>
			{title ? (
				type && <p className={styles.type}>{type}</p>
			) : (
				<p className={styles.type}>...</p>
			)}

			<h3 className={styles.title} title={decodeEntities(title)}>
				{title ? (
					getExcerptFromString(decodeEntities(title), 50)
				) : (
					<>
						<SkeletonLine size="full" />
						<SkeletonLine size="big" />
					</>
				)}
			</h3>

			<p className={styles.content}>
				{content ? (
					getExcerptFromString(decodeEntities(content), 130)
				) : (
					<>
						<SkeletonLine size="full" />
						<SkeletonLine size="full" />
						<SkeletonLine size="full" />
						<SkeletonLine size="big" />
					</>
				)}
			</p>

			<p className={styles.localisation}>
				{title ? localisation : <SkeletonLine size="small" />}
			</p>

			<Link title={title} className={styles.link} to={pathname}>
				<Arrow direction="right" />
			</Link>
		</article>
	);
}

export default VignetteOffre;
