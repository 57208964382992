import styles from './breakpoints.module.scss';

export type Breakpoint =
	| '<mobileS'
	| '<=mobileS'
	| '>=mobileS'
	| '>mobileS'
	| '<mobileM'
	| '<=mobileM'
	| '>=mobileM'
	| '>mobileM'
	| '<mobileL'
	| '<=mobileL'
	| '>=mobileL'
	| '>mobileL'
	| '<tablet'
	| '<=tablet'
	| '>=tablet'
	| '>tablet'
	| '<laptop'
	| '<=laptop'
	| '>=laptop'
	| '>laptop'
	| '<laptopL'
	| '<=laptopL'
	| '>=laptopL'
	| '>laptopL'
	| '<desktop'
	| '<=desktop'
	| '>=desktop'
	| '>desktop';

let breakpoints: any = {};

Object.entries(styles).forEach(([key, value]) => {
	const px = parseInt(value);
	breakpoints[`<${key}`] = `(max-width: ${px - 1}px)`;
	breakpoints[`<=${key}`] = `(max-width: ${px}px)`;
	breakpoints[`>=${key}`] = `(min-width: ${px}px)`;
	breakpoints[`>${key}`] = `(min-width: ${px + 1}px)`;
});

export const media: { [key in Breakpoint]: string } = breakpoints;
