import React from 'react';
import styles from './SkeletonLine.module.scss';

interface SkeletonLineProps {
	size: 'small' | 'big' | 'full';
}

function SkeletonLine({ size }: SkeletonLineProps) {
	return (
		<span>
			<span className={[styles.line, styles[size]].join(' ')}>&nbsp;</span>
		</span>
	);
}

export default SkeletonLine;
