import { TranslatedRoute } from '.';

const slides = ['persona', 'vision', 'international'] as const;

export default {
	international: {
		fr: '/fr/international',
		en: '/en/international',
	},
	persona: {
		fr: '/fr/persona',
		en: '/en/persona',
	},
	vision: {
		fr: '/fr/vision',
		en: '/en/vision',
	},
} as Record<typeof slides[number], TranslatedRoute>;
