import { decodeEntities } from '@wordpress/html-entities';
import dateformat from 'dateformat';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import routes from '../../routes';
import { Lang } from '../../types';
import { getExcerptFromString } from '../../utils/excerpt';
import { AcfImage, WordPressPostType } from '../../utils/wordpress/types';
import Arrow from '../Arrow/Arrow';
import WordPressImg from '../WordPressImg/WordPressImg';
import styles from './VignetteActualite.module.scss';

interface VignetteProps {
	slug: string;
	date: string;
	title: string;
	content: string;
	photo: AcfImage;
	type: WordPressPostType;
}

function VignetteActualite({
	type,
	slug,
	date,
	title,
	photo,
	content,
}: VignetteProps) {
	const { search } = useLocation();
	const { t, i18n } = useTranslation('common');

	const route = type === 'etude' ? routes.étude : routes.actualité;

	const pathname = route[i18n.language as Lang].replace(':slug', slug);

	return (
		<article className={styles.wrapper}>
			<figure className={styles.photo}>
				<Link title={decodeEntities(title)} to={{ pathname, search }}>
					{photo && (
						<WordPressImg
							wpSizes={photo.sizes}
							alt={photo.alt || title}
							sizes={{ '<tablet': 'calc(100vw - 70px)', default: '300px' }}
						/>
					)}
				</Link>
			</figure>

			<p className={styles.date}>{dateformat(date, 'dd/mm/yyyy')}</p>

			<h3 className={styles.title} title={decodeEntities(title)}>
				{getExcerptFromString(decodeEntities(title), 50)}
			</h3>

			<p className={styles.content}>
				{getExcerptFromString(decodeEntities(content), 130)}
			</p>

			{type === 'etude' && (
				<p className={styles.categorie}>{t('categories.' + type)}</p>
			)}

			<Link
				className={styles.link}
				to={{ pathname, search }}
				title={decodeEntities(title)}
			>
				<Arrow direction="right" />
			</Link>
		</article>
	);
}

export default VignetteActualite;
