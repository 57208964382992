import React from 'react';
import styles from './CallToAction.module.scss';

interface CallToActionProps {
	children: string;
	className?: string;
	animated?: boolean;
}

function CallToAction({ children, className, animated }: CallToActionProps) {
	return (
		<span
			className={[styles.wrapper, animated && styles.animated, className]
				.filter(Boolean)
				.join(' ')}
		>
			<span className={styles.rect} />
			<span className={styles.text}>
				{/* névessaire de wrap dans un sous-span (bug sur firefox) */}
				<span>{children}</span>
			</span>
			<span className={styles.line} />
		</span>
	);
}

export default CallToAction;
