import React from 'react';
import { useTranslation } from 'react-i18next';
import routes from '../../routes';
import BigTitle from '../BigTitle/BigTitle';
import CallToAction from '../CallToAction/CallToAction';
import Head from '../Head/Head';
import Link from '../Link/Link';
import styles from './PageNotFound.module.scss';

function PageNotFound() {
	const { t, ready } = useTranslation('pageNotFound');

	return ready ? (
		<div className={styles.wrapper}>
			<Head title={t('title')} description={t('description')} noindex />

			<BigTitle tag="h1" size="medium" className={styles.title} startsPage>
				{t('title')}
			</BigTitle>

			<p className={styles.text}>{t('description')}</p>

			<Link to={routes.splashScreen} className={styles.link}>
				<CallToAction>{t('backToHome')}</CallToAction>
			</Link>
		</div>
	) : null;
}

export default PageNotFound;
