import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Head from '../../components/Head/Head';
import { listSecteurs } from '../../routes/secteurs';
import { WithAnimatedRouteProps } from '../AnimatedRoute/AnimatedRoute';
import FooterPersona from '../FooterPersona/FooterPersona';
import LoadingPage from '../LoadingPage/LoadingPage';
import Description from './Description/Description';
import Etudes from './Etudes/Etudes';
import Projets from './Projets/Projets';

function Secteur({ url }: WithAnimatedRouteProps) {
	const { t, ready } = useTranslation('secteurs');

	const secteur =
		listSecteurs.find(({ routes }) => [routes.fr, routes.en].includes(url)) ||
		null;

	const oldSecteur = useRef<typeof secteur>();

	useEffect(() => {
		if (oldSecteur.current) window.scrollTo(0, 0);
		oldSecteur.current = secteur;
	}, [secteur]);

	return (
		secteur &&
		(ready ? (
			<>
				<Head
					title={t(`secteurs:${secteur.key}.title`)}
					description={t(`secteurs:${secteur.key}.description`)}
					img={`/img/opengraph/${secteur.key}.png`}
				/>

				<Description secteur={secteur.key} />

				<Etudes secteur={secteur.key} />

				<Projets secteur={secteur.key} />

				<FooterPersona />
			</>
		) : (
			<LoadingPage />
		))
	);
}

export default Secteur;
