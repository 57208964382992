import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga';
import { useTranslation } from 'react-i18next';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import Actualite from '../components/Actualite/Actualite';
import Actualites from '../components/Actualites/Actualites';
import AnimatedRoute from '../components/AnimatedRoute/AnimatedRoute';
import Carrieres from '../components/Carrieres/Carrieres';
import CookiesConsent from '../components/CookiesConsent/CookiesConsent';
import EtudeDeCas from '../components/EtudeDeCas/EtudeDeCas';
import Expertises from '../components/Expertises/Expertises';
import Header from '../components/Header/Header';
import Home from '../components/Home/Home';
import International from '../components/International/International';
import MentionsLegales from '../components/MentionsLegales/MentionsLegales';
import ModulePersona, {
	PersonaContext,
} from '../components/ModulePersona/ModulePersona';
import Offre from '../components/Offre/Offre';
import PageNotFound from '../components/PageNotFound/PageNotFound';
import Persona from '../components/Persona/Persona';
import Secteur from '../components/Secteur/Secteur';
import Slider from '../components/Slider/Slider';
import SplashScreen from '../components/SplashScreen/SplashScreen';
import Vision from '../components/Vision/Vision';
import routes, { allRoutes, homeRoutes, personaRoutes } from '../routes';
import { listSecteurs } from '../routes/secteurs';
import { isProd } from '../utils/env';

const allFlatRoutes = allRoutes.map((tr) => Object.values(tr)).flat();
const flatHomeRoutes = homeRoutes.map(Object.values).flat();
const flatPersonaRoutes = personaRoutes.map(Object.values).flat();
const flatSecteursRoutes = listSecteurs
	.map((r) => Object.values(r.routes))
	.flat();

function App() {
	const { i18n, ready } = useTranslation();
	const { pathname } = useLocation();
	const [personaOpen, setPersonaOpen] = useState(false);

	// Analytics
	useEffect(() => {
		if (isProd) ReactGA.pageview(pathname);
	}, [pathname]);

	// Resize (pour le 100vh en CSS)
	useEffect(() => {
		function resize() {
			let vh = window.innerHeight * 0.01;
			document.documentElement.style.setProperty('--vh', `${vh}px`);
		}

		resize();
		window.addEventListener('resize', resize);

		return () => {
			window.removeEventListener('resize', resize);
		};
	}, []);

	return (
		<PersonaContext.Provider
			value={{
				isOpen: personaOpen,
				open: () => setPersonaOpen(true),
				close: () => setPersonaOpen(false),
			}}
		>
			<Header />

			<CookiesConsent />

			{/* SplashScreen */}
			<AnimatedRoute
				component={SplashScreen}
				path={Object.values(routes.splashScreen)}
			/>

			{/* Home */}
			<AnimatedRoute path={flatHomeRoutes} component={Home} />

			{/* Personas */}
			<AnimatedRoute
				withScrollToTop
				component={Persona}
				path={flatPersonaRoutes}
			/>

			{/* Vision */}
			<AnimatedRoute
				withScrollToTop
				component={Vision}
				path={Object.values(routes.vision)}
			/>

			{/* International */}
			<AnimatedRoute
				component={International}
				path={Object.values(routes.carte)}
			/>

			{/* Secteurs */}
			<AnimatedRoute
				withScrollToTop
				component={Secteur}
				path={flatSecteursRoutes}
			/>

			{/* Expertises */}
			<AnimatedRoute
				withScrollToTop
				component={Expertises}
				path={Object.values(routes.expertises)}
			/>

			{/* Actualités */}
			<AnimatedRoute
				withScrollToTop
				component={Actualites}
				path={Object.values(routes.actualités)}
			/>

			<AnimatedRoute
				withScrollToTop
				component={Actualite}
				path={Object.values(routes.actualité)}
			/>

			{/* Études de cas */}
			<AnimatedRoute
				withScrollToTop
				component={EtudeDeCas}
				path={Object.values(routes.étude)}
			/>

			{/* Carrières */}
			<AnimatedRoute
				withScrollToTop
				component={Carrieres}
				path={Object.values(routes.carrières)}
			/>

			<AnimatedRoute
				withScrollToTop
				component={Offre}
				path={Object.values(routes.offres)}
			/>

			{/* Mentions légales */}
			<AnimatedRoute
				withScrollToTop
				component={MentionsLegales}
				path={Object.values(routes['mentions-légales'])}
			/>

			<Slider />

			<ModulePersona />

			{/* Redirects & 404 */}
			<Switch>
				{ready && (
					<Route exact path="/">
						<Redirect to={`/${i18n.language}`} />
					</Route>
				)}

				{/* on affiche PageNotFound que si on est sur une route non répertoriée */}
				<Route path={Object.values(routes[404])}>
					<PageNotFound />
				</Route>
				<Route path={allFlatRoutes} exact />
				<Route>
					<PageNotFound />
				</Route>
			</Switch>
		</PersonaContext.Provider>
	);
}

export default App;
