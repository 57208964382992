import { frenchify } from 'frenchify';
import React, { ReactNode } from 'react';
import { useInView } from 'react-intersection-observer';
import { TranslatedRoute } from '../../routes';
import CallToAction from '../CallToAction/CallToAction';
import Link from '../Link/Link';
import styles from './TextBlock.module.scss';

type HTML = { html: string; className?: string };

interface TextBlockProps {
	title?: ReactNode;
	className: string;
	content: ReactNode | HTML;
	level?: 'h2' | 'h3' | 'h4';
	link?: { to: TranslatedRoute; children: string; animated?: boolean };
	button?: { onClick: () => void; children: string; animated?: boolean };
}

function TextBlock({
	link,
	title,
	button,
	content,
	className,
	level: Tag = 'h2',
}: TextBlockProps) {
	const [ref, inView] = useInView({
		triggerOnce: true,
		rootMargin: '-100px 0px 0px',
	});

	return (
		<section
			ref={ref}
			className={[styles.wrapper, className, inView && styles.inView]
				.filter(Boolean)
				.join(' ')}
		>
			{title && <Tag className={styles.title}>{title}</Tag>}

			{content &&
				(typeof content === 'object' && 'html' in content ? (
					<div
						dangerouslySetInnerHTML={{
							__html: frenchify((content as HTML).html || ''),
						}}
						className={[styles.content, (content as HTML).className]
							.filter(Boolean)
							.join(' ')}
					/>
				) : (
					<p className={styles.content}>{content}</p>
				))}

			{link && (
				<Link to={link.to} className={styles.cta}>
					<CallToAction animated={link.animated}>{link.children}</CallToAction>
				</Link>
			)}

			{button && (
				<button onClick={button.onClick} className={styles.cta}>
					<CallToAction animated={button.animated}>
						{button.children}
					</CallToAction>
				</button>
			)}
		</section>
	);
}

export default TextBlock;
