import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Facebook } from './facebook.svg';
import { ReactComponent as LinkedIn } from './linkedin.svg';
import styles from './Share.module.scss';
import { ReactComponent as Twitter } from './twitter.svg';

interface ShareProps {
	title: string;
	className?: string;
}

function Share({ title, className }: ShareProps) {
	const { t, ready } = useTranslation('common');

	const url = encodeURI(window.location.origin + window.location.pathname);

	return (
		<div className={[styles.wrapper, className].filter(Boolean).join(' ')}>
			<p className={styles.text}>{ready && t('share')}</p>

			<SocialLink
				title="Facebook"
				href={`https://www.facebook.com/sharer.php?u=${url}`}
			>
				<Facebook className={styles.svg} />
			</SocialLink>

			<SocialLink
				title="Twitter"
				href={`https://twitter.com/share?url=${url}&text=${title}&via=H3PGroup`}
			>
				<Twitter className={styles.svg} />
			</SocialLink>

			<SocialLink
				title="LinkedIn"
				href={`https://www.linkedin.com/shareArticle?url=${url}&title=${title}`}
			>
				<LinkedIn className={styles.svg} />
			</SocialLink>

			{/* <SocialLink href={`mailto:?subject=${title}&body=${url}`}>
				email
			</SocialLink> */}
		</div>
	);
}

type SocialLinkProps = { href: string; title: string; children: ReactNode };

function SocialLink({ children, href, title }: SocialLinkProps) {
	return (
		<a
			href={href}
			title={title}
			target="_blank"
			className={styles.link}
			rel="noopener noreferrer"
		>
			{children}
		</a>
	);
}

export default Share;
