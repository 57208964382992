import React from 'react';
import { orcomURL } from '../../../routes';
import { ReactComponent as LogoSVG } from '../../../utils/logo-orcom.svg';
import styles from './LogoOrcom.module.scss';

interface LogoOrcomProps {
	className: string;
}

function LogoOrcom({ className }: LogoOrcomProps) {
	return (
		<a
			href={orcomURL}
			target="_blank"
			rel="noopener noreferrer"
			className={[styles.wrapper, className].join(' ')}
		>
			<LogoSVG className={styles.svg} />
		</a>
	);
}

export default LogoOrcom;
