import React, { FormEvent, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { CSSTransition } from 'react-transition-group';
import slugify from 'slugify';
import { Lang } from '../../types';
import { searchWordPressPosts } from '../../utils/wordpress';
import SearchButton from '../Header/SearchButton/SearchButton';
import XButton from '../XButton/XButton';
import Form from './Form/Form';
import { listing } from './listing';
import styles from './ModuleRecherche.module.scss';
import Results from './Results/Results';

export type Result = { title: string; url: string; type: string };

interface ModuleRechercheProps {
	isOpen: boolean;
	closeRecherche: () => void;
}

function ModuleRecherche({ isOpen, closeRecherche }: ModuleRechercheProps) {
	const { t, ready, i18n } = useTranslation('common');

	const [search, setSearch] = useState('');
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState('');
	const [searchActive, setSearchActive] = useState(false);
	const [results, setResults] = useState<Result[]>([]);

	async function triggerSearch(e: FormEvent) {
		e.preventDefault();

		setLoading(true);
		setSearchActive(true);

		const searchWords = slugify(search, { lower: true }).split('-');

		const pages = listing
			.filter(item => searchWords.every(s => item.keywords.includes(s)))
			.map(r => ({
				type: 'page',
				title: t(r.trad),
				url: r.route[i18n.language as Lang],
			}));

		try {
			const res = await searchWordPressPosts(search);
			setResults([...pages, ...res]);
		} catch (err) {
			setError(err.message);
		} finally {
			setLoading(false);
		}
	}

	useEffect(() => {
		if (!isOpen) return;
		setSearch('');
		setSearchActive(false);
	}, [isOpen]);

	return ready
		? createPortal(
				<CSSTransition
					in={isOpen}
					mountOnEnter
					unmountOnExit
					classNames={styles}
					timeout={{ exit: +styles.duration }}
				>
					{state => (
						<div className={styles.wrapper}>
							<Form
								state={state}
								search={search}
								setSearch={setSearch}
								onSubmit={triggerSearch}
								searchActive={searchActive}
							/>

							<Results
								state={state}
								error={error}
								loading={loading}
								results={results}
								hidden={!searchActive}
								onLinkClick={closeRecherche}
							/>

							<SearchButton
								onClick={closeRecherche}
								className={styles.searchButton}
							/>

							<div className={styles.close}>
								<XButton onClick={closeRecherche} className={styles.closeBtn} />
							</div>

							<div className={styles.cache} />
						</div>
					)}
				</CSSTransition>,
				document.getElementById('popin')!,
		  )
		: null;
}

export default ModuleRecherche;
