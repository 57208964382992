import React from 'react';
import { useTranslation } from 'react-i18next';
import { SecteurTypes } from '../../../routes/secteurs';
import BigText from '../../BigText/BigText';
import Clients from '../../Clients/Clients';
import styles from './Projets.module.scss';
import { data } from './variables';

function Projets({ secteur }: { secteur: SecteurTypes }) {
	const { t } = useTranslation('secteurs');

	return (
		<section className={styles.wrapper}>
			<h2 className={styles.title}>
				<BigText className={styles.big}>
					{[{ word: data[secteur].projets.toString() }]}
				</BigText>

				<BigText className={styles.big}>
					{[{ word: t(data[secteur].typeProjet), stroke: true }]}
				</BigText>

				{/* <BigText className={styles.small}>
					{[
						{ word: data[secteur].collaborateurs.toString() },
						{ word: t('collaborateurs') },
					]}
				</BigText> */}
			</h2>

			<Clients className={styles.clients} clients={data[secteur].clients} />
		</section>
	);
}

export default Projets;
