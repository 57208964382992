import React, { CSSProperties } from 'react';
import { media } from '../../../utils/breakpoints/breakpoints';
import { getSrcSet } from '../../../utils/img';
import styles from './SlideImg.module.scss';

// constante qui ne sert que pour les sizes et qui correspond à la moyenne de la taille de l'image en vw
export const slideImgWidth = { desktop: 48.8, mobile: 89.4 };

interface SlideImgProps {
	y: number;
	x: number;
	src: string;
	width: number;
	widths: number[];
	className?: string;
	style?: CSSProperties;
}

function SlideImg({
	x,
	y,
	widths,
	src,
	width,
	className,
	style,
}: SlideImgProps) {
	const generated = getSrcSet({ ext: 'png', src, widths });
	const sizes = `${media['<tablet']} ${(width * slideImgWidth.mobile) /
		100}vw, ${(width * slideImgWidth.desktop) / 100}vw`;

	return (
		<img
			alt=""
			src={generated.src}
			className={[styles.img, className].filter(Boolean).join(' ')}
			{...(widths.length > 1 && { sizes, srcSet: generated.srcSet })}
			style={{ ...style, top: y + '%', left: x + '%', width: width + '%' }}
		/>
	);
}

export default SlideImg;
