import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

i18n
	.use(Backend)
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		whitelist: ['fr', 'en'],
		fallbackLng: 'en',
		ns: 'common',
		defaultNS: 'common',
		interpolation: { escapeValue: false },
		detection: { order: ['path', 'cookie', 'localStorage', 'navigator'] },
		saveMissing: true,
		react: { useSuspense: false },
		missingKeyHandler(lng, ns, key) {
			console.warn(`Missing translation for ${lng}:${ns}:${key}`);
		},
	});

export default i18n;
