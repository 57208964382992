import { useEffect, useState } from 'react';

export function useScrolled() {
	const [scrolled, setScrolled] = useState(false);

	useEffect(() => {
		handleScroll();

		function handleScroll() {
			if (window.pageYOffset > 0 && !scrolled) setScrolled(true);
			else if (window.pageYOffset === 0 && scrolled) setScrolled(false);
		}

		window.addEventListener('scroll', handleScroll);

		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, [scrolled]);

	return { scrolled };
}
