import React, { Component, createRef } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { SecteurTypes } from '../../routes/secteurs';
import { Lang } from '../../types';
import Filtre, { Option } from '../Filtre/Filtre';
import FiltreRadio from '../FiltreRadio/FiltreRadio';
import Head from '../Head/Head';
import LoadingPage from '../LoadingPage/LoadingPage';
import styles from './International.module.scss';
import { PointType, SuperMap } from './map';

const filtresGroupes: Option[] = [
	{ label: 'H3P', value: 'h3p' },
	{ label: 'Orcom', value: 'orcom' },
	{ label: 'Partenaires', value: 'englobally' },
];

const types: PointType[] = ['projets', 'implantations'];

const secteurs: SecteurTypes[] = [
	'energies-utilities',
	'immobilier',
	'infrastructures-sociales',
	'mobilite-smart-city',
	'routes-transports',
	'telecoms',
];

interface State {
	type: PointType;
	groupe?: Option;
	secteur?: Option;
}

class International extends Component<WithTranslation, State> {
	state: State = { type: types[0] };

	map?: SuperMap;

	mapDiv = createRef<HTMLDivElement>();

	componentDidMount() {
		// les trads sont peut-être déjà chargées
		// (via workbox ou parce que la page a déjà été visitée)
		// donc on peut trigger un createMap à cet endroit
		this.createMap();
	}

	componentDidUpdate() {
		if (this.map) {
			this.map.lang = this.props.i18n.language as Lang;
			this.updateMapFilters();
		} else {
			this.createMap(); // si les trads n'étaient pas chargées au didMount, il faut re-trigger un createMap ici
		}
	}

	createMap() {
		import('./map').then(({ SuperMap }) => {
			if (this.map || !this.mapDiv.current) return;

			this.map = new SuperMap({
				container: this.mapDiv.current,
				lang: this.props.i18n.language as Lang,
				callback: () => this.updateMapFilters(),
			});
		});
	}

	updateMapFilters() {
		this.map?.filter({
			type: this.state.type,
			groupe: this.state.groupe?.value,
			secteur: this.state.secteur?.value,
		});
	}

	render() {
		const { t, tReady } = this.props;

		const filtres = tReady
			? types.map(
					(type) =>
						({ label: t(`international:${type}`), value: type } as Option),
			  )
			: [];

		return tReady ? (
			<section className={styles.wrapper}>
				<Head
					title={t('international:title')}
					description={t('international:description')}
					img="/img/opengraph/international.png"
				/>

				<FiltreRadio
					options={filtres}
					className={styles.filtre}
					current={filtres.find((f) => f.value === this.state.type) as Option}
					setOption={(option) =>
						this.setState({
							type: option.value as PointType,
							groupe: undefined,
							secteur: undefined,
						})
					}
				/>

				{this.state.type === 'implantations' ? (
					<Filtre
						color="blue"
						options={filtresGroupes}
						current={this.state.groupe}
						className={styles.sousFiltre}
						title={t('international:groupes')}
						setOption={(groupe) => this.setState({ groupe })}
					/>
				) : (
					<Filtre
						color="blue"
						current={this.state.secteur}
						className={styles.sousFiltre}
						title={t('international:secteurs')}
						setOption={(secteur) => this.setState({ secteur })}
						options={secteurs.map((s) => ({
							label: t(`common:secteurs:${s}`),
							value: s,
						}))}
					/>
				)}

				<div ref={this.mapDiv} className={styles.map} />
			</section>
		) : (
			<LoadingPage />
		);
	}
}

export default withTranslation(['common', 'international'])(International);
