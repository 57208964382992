import React, { ReactNode } from 'react';
import styles from './Square.module.scss';

interface SquareProps {
	className: string;
	children: ReactNode;
}

function Square({ className, children }: SquareProps) {
	return (
		<div className={[styles.wrapper, className].join(' ')}>{children}</div>
	);
}

export default Square;
