import React, { memo } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import styles from './Pagination.module.scss';

interface PaginationProps {
	total: number;
	perPage: number;
	className: string;
}

function Pagination({ className, total, perPage }: PaginationProps) {
	const { search, pathname } = useLocation();

	const pagesCount = Math.ceil(total / perPage);
	const list = [...new Array(pagesCount)];

	const searchParams = new URLSearchParams(search);
	const activePage = +(searchParams.get('page') || 1);

	function getPage(page: number) {
		searchParams.set('page', page.toString());
		return pathname + '?' + searchParams;
	}

	return (
		<div className={[styles.wrapper, className].filter(Boolean).join(' ')}>
			<nav className={styles.nav}>
				{/* Prev */}
				<Link
					to={getPage(activePage - 1)}
					className={[
						styles.link,
						styles.prev,
						activePage <= 1 && styles.disabled,
					]
						.filter(Boolean)
						.join(' ')}
				/>

				{/* Next */}
				<Link
					to={getPage(activePage + 1)}
					className={[
						styles.link,
						styles.next,
						activePage >= pagesCount && styles.disabled,
					]
						.filter(Boolean)
						.join(' ')}
				/>

				{/* Numéros */}
				{list.map((_, i) => {
					i = i + 1;
					if (
						![
							1,
							activePage - 1,
							activePage,
							activePage + 1,
							activePage + 2,
							pagesCount,
						].includes(i)
					) {
						if (i === 2 || i === pagesCount - 1) {
							return (
								<span
									key={i}
									className={[styles.link, styles.disabled].join(' ')}
								>
									...
								</span>
							);
						}
						return null;
					}
					return (
						<NavLink
							key={i}
							to={getPage(i)}
							className={styles.link}
							activeClassName={styles.active}
							isActive={() => activePage === i}
						>
							{i}
						</NavLink>
					);
				})}
			</nav>
		</div>
	);
}

export default memo(Pagination, (prev, next) => {
	return prev.total === next.total;
});
