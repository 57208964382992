import React from 'react';
import { useTranslation } from 'react-i18next';
import routes from '../../../../routes';
import Link from '../../../Link/Link';
import styles from './Menu.module.scss';

const pages = Object.entries(routes.secteurs);

function Menu({ className }: { className: string }) {
	const { t } = useTranslation('secteurs');

	return (
		<nav className={[styles.wrapper, className].join(' ')}>
			<p className={styles.title}>{t('menuTitle')}</p>

			<ul>
				{pages.map(([key, routes]) => (
					<li key={key}>
						<Link
							to={routes}
							className={styles.link}
							activeClassName={styles.active}
						>
							<span>{t(`${key}.title`)}</span>
						</Link>
					</li>
				))}
			</ul>
		</nav>
	);
}

export default Menu;
