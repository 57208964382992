import { ComponentProps } from 'react';
import { SecteurTypes } from '../../routes/secteurs';
import { ImgSizes } from '../../utils/img';
import Illustration from './Illustration';
import styles from './Illustration.module.scss';

const backgroundWidths: number[] = [390, 500, 780, 1000, 1430, 2480];
const backgroundSliderWidths: number[] = [380, 760, 980, 1380, 1500, 1960];
const backgroundSizes: ImgSizes = {
	default: '49.5vw',
	'<desktop': '48.5vw',
	'<laptop': '50.8vw',
	'<tablet': '89.4vw',
};

export type ReadyIllustration = Omit<
	ComponentProps<typeof Illustration>,
	'alt' | 'className' | 'parallax'
>;

const pales = {
	widths: [132],
	sizes: { default: '132px' },
	className: styles.pales,
	src: '/img/pales/pales',
};

const actualites: ReadyIllustration = {
	color: '#fdceb3',
	background: {
		sizes: backgroundSizes,
		widths: backgroundWidths,
		src: '/img/actualites/fond/fond',
	},
	animations: [
		{
			widths: [85, 173],
			sizes: { default: '3.5vw' },
			pos: { w: 8.7, x: 44.2, y: 23.6 },
			src: '/img/actualites/cheveux/cheveux',
		},
	],
};

const carrieres: ReadyIllustration = {
	color: '#9ee1f5',
	background: {
		sizes: backgroundSizes,
		widths: backgroundWidths,
		src: '/img/carrieres/fond/fond',
	},
	animations: [
		{
			widths: [126],
			sizes: { default: '126px' },
			pos: { w: 5.5, x: 59, y: 8.3 },
			src: '/img/carrieres/drapeau/drapeau',
		},
	],
};

const collaboratif: ReadyIllustration = {
	color: '#f69d7c',
	background: {
		sizes: backgroundSizes,
		widths: backgroundWidths,
		src: '/img/vision/collaboratif/illustration/fond/fond',
	},
	animations: [
		{
			widths: [200, 100, 516],
			sizes: { default: '40vw' },
			pos: { w: 23, x: 20.9, y: 61.2 },
			src: '/img/vision/collaboratif/illustration/eau/eau1',
		},
		{
			widths: [310, 150, 960],
			sizes: { default: '16.5vw', '<tablet': '29.9vw' },
			pos: { w: 38.8, x: 61.2, y: 63.8 },
			src: '/img/vision/collaboratif/illustration/eau/eau2',
		},
		{
			widths: [430, 180, 1197],
			sizes: { default: '25.6vw', '<tablet': '40vw' },
			pos: { w: 52.7, x: 35.1, y: 53.9 },
			src: '/img/vision/collaboratif/illustration/aviron/aviron',
		},
	],
};

const energiesUtilities: ReadyIllustration = {
	color: '#94ceb0',
	background: {
		sizes: backgroundSizes,
		widths: backgroundWidths,
		src: '/img/secteurs/energies-utilities/fond/fond',
	},
	animations: [
		{
			widths: [72, 135],
			sizes: { default: '3.9vw', '<tablet': '7.7vw' },
			pos: { w: 8.7, x: 33.7, y: 59 },
			src: '/img/secteurs/energies-utilities/cheveux/cheveux',
		},
		{
			widths: [200, 100, 567],
			sizes: { default: '12.5vw', '<tablet': '24.9vw' },
			className: [styles.pales, styles.energie].join(' '),
			pos: { w: 27.9, x: 48.5, y: 7 },
			src: '/img/secteurs/energies-utilities/pales/pales',
		},
		{
			widths: [79],
			sizes: { default: '79px' },
			pos: { w: 4, x: 63, y: 23.9 },
			src: '/img/secteurs/energies-utilities/bite/bite',
		},
	],
};

const routesTransports: ReadyIllustration = {
	color: '#68bbf1',
	background: {
		sizes: backgroundSizes,
		widths: backgroundWidths,
		src: '/img/secteurs/routes-transports/fond/fond',
	},
	animations: [
		{
			widths: [400, 200],
			sizes: { default: '20.6vw', '<tablet': '41.2vw' },
			pos: { w: 46.2, x: 0, y: 9.8 },
			src: '/img/secteurs/routes-transports/ombres/ombre1',
		},
		{
			widths: [300, 150],
			sizes: { default: '16.1vw', '<tablet': '32vw' },
			pos: { w: 36, x: 64, y: 55.8 },
			src: '/img/secteurs/routes-transports/ombres/ombre2',
		},
	],
};

const mobiliteSmartCity: ReadyIllustration = {
	color: '#86efdd',
	background: {
		sizes: backgroundSizes,
		widths: backgroundWidths,
		src: '/img/secteurs/mobilite-smart-city/fond/fond',
	},
	animations: [
		{
			widths: [786, 320, 160],
			sizes: { default: '14.1vw', '<tablet': '28vw' },
			pos: { w: 31.6, x: 50.3, y: 32.5 },
			src: '/img/secteurs/mobilite-smart-city/fenetres/fenetres',
		},
	],
};

const immobilier: ReadyIllustration = {
	color: '#e8ae68',
	background: {
		sizes: backgroundSizes,
		widths: backgroundWidths,
		src: '/img/secteurs/immobilier/fond/fond',
	},
	animations: [
		{
			widths: [179, 70],
			sizes: { default: '3.2vw', '<tablet': '6.3vw' },
			pos: { w: 7.2, x: 21.3, y: 45 },
			src: '/img/secteurs/immobilier/tete/tete',
		},
	],
};

const infrastructuresSociales: ReadyIllustration = {
	color: '#fbcb64',
	background: {
		sizes: backgroundSizes,
		widths: backgroundWidths,
		src: '/img/secteurs/infrastructures-sociales/fond/fond',
	},
	animations: [
		{
			widths: [160, 320],
			sizes: { default: '8.3vw', '<tablet': '16.5vw' },
			pos: { w: 18.6, x: 56.5, y: 38.3 },
			src: '/img/secteurs/infrastructures-sociales/ecole/ecole',
		},
	],
};

const telecoms: ReadyIllustration = {
	color: '#e5b694',
	background: {
		sizes: backgroundSizes,
		widths: backgroundWidths,
		src: '/img/secteurs/telecoms/fond/fond',
	},
	animations: [
		{
			widths: [143],
			sizes: { default: '143px' },
			pos: { w: 5.7, x: 40.1, y: 29.3 },
			src: '/img/secteurs/telecoms/tel/tel',
		},
	],
};

const engagement: ReadyIllustration = {
	color: '#ffbf71',
	background: {
		sizes: backgroundSizes,
		widths: backgroundWidths,
		src: '/img/vision/engagement/illustration/fond/fond',
	},
	animations: [
		{
			widths: [709, 423, 286, 143],
			sizes: { default: '10.5vw', '<tablet': '20.2vw' },
			pos: { w: 28.6, x: 40.4, y: 27.2 },
			src: '/img/vision/engagement/illustration/veste/veste',
		},
	],
};

const expertises: ReadyIllustration = {
	color: '#89d472',
	background: {
		sizes: backgroundSizes,
		widths: backgroundWidths,
		src: '/img/expertises/fond/fond',
	},
	animations: [
		{
			widths: [200, 100],
			sizes: { default: '5.2vw', '<tablet': '10.3vw' },
			pos: { w: 11.7, x: 26.7, y: 45.2 },
			src: '/img/expertises/iceberg/iceberg',
		},
		{ ...pales, pos: { w: 5.3, x: 81.3, y: 36 } },
		{ ...pales, pos: { w: 5.3, x: 77.3, y: 34.5 } },
		{ ...pales, pos: { w: 5.3, x: 69.3, y: 41.2 } },
		{ ...pales, pos: { w: 5.3, x: 73, y: 40.7 } },
		{ ...pales, pos: { w: 5.3, x: 58.9, y: 51.2 } },
		{ ...pales, pos: { w: 5.3, x: 63.2, y: 52.7 } },
	],
};

const vision: ReadyIllustration = {
	color: '#63b9c9',
	background: {
		sizes: backgroundSizes,
		widths: backgroundSliderWidths,
		src: '/img/home/vision/fond/fond',
	},
	animations: [
		{
			widths: [240, 480],
			sizes: { default: '13vw' },
			className: [styles.nuage, styles.n1].join(' '),
			pos: { w: 25, x: 0, y: 8 },
			src: '/img/home/nuages/nuage1',
		},
		{
			widths: [220, 440],
			sizes: { default: '13vw' },
			className: [styles.nuage, styles.n2].join(' '),
			pos: { w: 25, x: 0, y: 16 },
			src: '/img/home/nuages/nuage2',
		},
		{
			widths: [200, 400],
			sizes: { default: '13vw' },
			className: [styles.nuage, styles.n3].join(' '),
			pos: { w: 25, x: 0, y: 32 },
			src: '/img/home/nuages/nuage3',
		},
		{
			widths: [150, 300],
			sizes: { default: '13vw' },
			className: [styles.nuage, styles.n4].join(' '),
			pos: { w: 25, x: 0, y: 50 },
			src: '/img/home/nuages/nuage4',
		},
		{
			widths: [160, 320],
			sizes: { default: '13vw' },
			className: [styles.nuage, styles.n5].join(' '),
			pos: { w: 25, x: 0, y: 65 },
			src: '/img/home/nuages/nuage5',
		},
		{
			widths: [125, 250, 310, 450, 500, 620],
			sizes: { default: '17vw' },
			pos: { w: 34, x: 35, y: 16 },
			src: '/img/home/vision/cheval/cheval',
		},
		{
			widths: [120, 235],
			sizes: { default: '5.2vw' },
			pos: { w: 10.4, x: 53.9, y: 14.5 },
			src: '/img/home/vision/cravate/cravate',
		},
	],
};

const international: ReadyIllustration = {
	color: '#e5b694',
	background: {
		sizes: backgroundSizes,
		widths: backgroundSliderWidths,
		src: '/img/home/international/fond/fond',
	},
	animations: [
		{
			widths: [900, 1800, 1280, 330, 660],
			sizes: { default: '34.1vw', '<tablet': '61.4vw' },
			pos: { w: 83.7, x: 9.1, y: 12 },
			src: '/img/home/international/iles/iles',
		},
		{ ...pales, pos: { w: 3.5, x: 27.6, y: 26.5 } },
		{ ...pales, pos: { w: 3.5, x: 30.6, y: 25.9 } },
		{ ...pales, pos: { w: 3.5, x: 36.2, y: 26.9 } },
		{ ...pales, pos: { w: 3.5, x: 41.9, y: 29.1 } },
		{
			widths: [87],
			sizes: { default: '87px' },
			pos: { w: 3.5, x: 68.5, y: 19.5 },
			src: '/img/home/international/volcan/volcan',
		},
		{
			widths: [238],
			sizes: { default: '238px' },
			pos: { w: 9.6, x: 18.6, y: 33.5 },
			src: '/img/home/international/fenetres/fenetres',
		},
		{
			widths: [384, 120],
			sizes: { default: '6.6vw', '<tablet': '11.9vw' },
			pos: { w: 15.5, x: 73.6, y: 38.5 },
			src: '/img/home/international/fleuve/fleuve',
		},
		{
			widths: [89],
			sizes: { default: '89px' },
			pos: { w: 4, x: 20, y: 60 },
			className: styles.aileron,
			src: '/img/home/international/aileron/aileron',
		},
		{
			widths: [275],
			sizes: { default: '275px' },
			pos: { w: 11.1, x: 34.6, y: 79.6 },
			src: '/img/home/international/barques/barques',
		},
		{
			widths: [145],
			sizes: { default: '145px' },
			pos: { w: 5, x: 30, y: 54 },
			className: styles.voilier,
			src: '/img/home/international/voilier/voilier',
		},
		{
			widths: [147],
			sizes: { default: '147px' },
			pos: { w: 8, x: 0, y: 55.5 },
			className: styles.avion,
			src: '/img/home/international/avion/avion',
		},
		{
			widths: [147],
			sizes: { default: '147px' },
			pos: { w: 8, x: 0, y: 55.5 },
			className: styles.ombreAvion,
			src: '/img/home/international/avion/avion',
		},
	],
};

export const illustrations = {
	actualites,
	carrieres,
	secteurs: {
		'energies-utilities': energiesUtilities,
		'infrastructures-sociales': infrastructuresSociales,
		'mobilite-smart-city': mobiliteSmartCity,
		'routes-transports': routesTransports,
		immobilier,
		telecoms,
	} as { [key in SecteurTypes]: ReadyIllustration },
	collaboratif,
	engagement,
	expertises,
	vision,
	international,
};
