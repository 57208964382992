import { Breakpoint, media } from './breakpoints/breakpoints';

interface GetSrcSetProps {
	src: string;
	widths: number[];
	ext: 'jpg' | 'png' | 'webp';
}

export function getSrcSet({ src, ext, widths }: GetSrcSetProps) {
	return {
		src: `${src}@${widths[0]}.${ext}`,
		srcSet: widths.map((w) => `${src}@${w}.${ext} ${w}w`).join(', '),
	};
}

export type ImgSizes = { default: string } & Partial<
	{
		[key in Breakpoint]: string;
	}
>;

export function getSizes(props: ImgSizes) {
	const { default: defaultSize, ...facultativeSizes } = props;

	const otherSizes = Object.entries(facultativeSizes) as [Breakpoint, string][];

	return [
		...otherSizes.map(([breakpoint, width]) => media[breakpoint] + ' ' + width),
		defaultSize,
	].join(', ');
}
