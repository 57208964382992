import React from 'react';
import { useInView } from 'react-intersection-observer';
import styles from './Quotation.module.scss';

interface QuotationProps {
	author: string;
	content: string;
	className: string;
	small?: boolean;
}

function Quotation({ author, content, className, small }: QuotationProps) {
	const [ref, inView] = useInView({
		triggerOnce: true,
		rootMargin: '-100px 0px 0px',
	});

	return (
		<blockquote
			ref={ref}
			className={[
				styles.wrapper,
				className,
				inView && styles.inView,
				small && styles.small,
			]
				.filter(Boolean)
				.join(' ')}
		>
			<p className={styles.content}>«&#8239;{content}&#8239;»</p>
			<footer className={styles.author}>{author}</footer>
		</blockquote>
	);
}

export default Quotation;
