import routes, { TranslatedRoute } from '../../routes';

export const listing: {
	trad: string;
	route: TranslatedRoute;
	keywords: string;
}[] = [
	{
		trad: 'menu.vision',
		route: routes.vision,
		keywords: [
			'projets',
			'expertises',
			'international',
			'finances',
			'audits',
		].join('-'),
	},
	// {
	// 	trad: 'menu.vision',
	// 	route: routes.home.persona,
	// 	keywords: [
	// 		'actifs',
	// 		'collectivites',
	// 		'confiance',
	// 		'credits',
	// 		'developpements',
	// 		'developpeurs',
	// 		'durables',
	// 		'expertises',
	// 		'exploitants',
	// 		'financements',
	// 		'financiers',
	// 		'gestions',
	// 		'gestionnaires',
	//		'actifs',
	// 		'infrastructures',
	// 		'investissements',
	// 		'investisseurs',
	// 		'negociations',
	// 		'operations',
	// 		'personnes',
	// 		'publiques',
	// 		'prêteurs',
	// 		'projets',
	// 		'services',
	// 		'suivis',
	// 	].join('-'),
	// },
	{
		trad: 'menu.expertises',
		route: routes.expertises,
		keywords: ['experts', 'gestions', 'projets', 'financements', 'fonds'].join(
			' ',
		),
	},
	{
		trad: 'menu.carrieres',
		route: routes.carrières,
		keywords: [
			'equipes',
			'projets',
			'expertises',
			'mondes',
			'accompagnements',
		].join('-'),
	},
	{
		trad: 'secteurs.routes-transports',
		route: routes.secteurs['routes-transports'],
		keywords: [
			'transports',
			'developpements',
			'enjeux',
			'mondialisations',
			'reseaux',
		].join('-'),
	},
	{
		trad: 'secteurs.energies-utilities',
		route: routes.secteurs['energies-utilities'],
		keywords: [
			'energies',
			'innovations',
			'developpements',
			'durables',
			'energies',
			'renouvelables',
			'utilities',
		].join('-'),
	},
	{
		trad: 'secteurs.mobilite-smart-city',
		route: routes.secteurs['mobilite-smart-city'],
		keywords: [
			'mobilites',
			'smart',
			'city',
			'cities',
			'donnees',
			'technologiques',
			'villes',
		].join('-'),
	},
	{
		trad: 'secteurs.telecoms',
		route: routes.secteurs.telecoms,
		keywords: [
			'telecoms',
			'reseaux',
			'donnees',
			'consommation',
			'developpements',
		].join('-'),
	},
	{
		trad: 'secteurs.immobilier',
		route: routes.secteurs.immobilier,
		keywords: [
			'immobiliers',
			'actifs',
			'reglementations',
			'investissements',
			'sci',
		].join('-'),
	},
	{
		trad: 'secteurs.infrastructures-sociales',
		route: routes.secteurs['infrastructures-sociales'],
		keywords: [
			'infrastructures',
			'publiques',
			'sociales',
			'investissements',
			'projets',
		].join('-'),
	},
];
