import React from 'react';
import styles from './Arrow.module.scss';

interface ArrowProps {
	direction: 'left' | 'right';
}

function Arrow({ direction }: ArrowProps) {
	return (
		<span className={[styles.wrapper, styles[direction]].join(' ')}>
			<span className={styles.line}>
				<span className={styles.triangle} />
			</span>
		</span>
	);
}

export default Arrow;
